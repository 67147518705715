@import "../../../../config.scss";

.noStatus {
    display: flex;
    justify-content: center;
    padding-top: 100px;

    p {
        margin: 0;
    }
}

.container {
    padding: 24px 0;
    display: grid;
    grid-template-columns: max-content 40%;
    align-items: center;
    gap: 24px;
}

.formLabel {
    color: $bluegray-700;
}
