@import "../../../styles.scss";
@import "../../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.subText {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
