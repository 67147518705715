@import "../../../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statusIcon {
    display: inline-block;
    vertical-align: top;
    font-size: 20px;
    margin-right: $spacing-1x;
}

.statusIconAttached {
    color: $success-dark;
}

.statusIconInactive {
    color: $text-darker;
}

.statusLabel {
    font-weight: 600;
}
