@import "../../config.scss";

.serviceTunnel {
    display: flex;
}

.createInfraWarning {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.list {
    list-style: none;
}

.item {
    margin-bottom: 8px;
}

.collision {
    color: $feedback-error-dark;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
    row-gap: 20px;
}

.errorContainer {
    margin: 0 24px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.tunnelForm {
    display: flex;
    flex-direction: column;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
    height: 24px;
}

.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 16px;
}
