@import "../../../config.scss";
@import "../../../styles/page.scss";
@import "../../../styles/dynamic-form.scss";

.leftButton {
    margin-right: $spacing-1x;
}

.splitColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $spacing-3x;
}

.inlineLabel {
    display: flex;
}

.checkboxLabel {
    margin-top: $spacing-5x;
    margin-left: $spacing-2x;
}

.splitLabelContainer {
    margin-bottom: 0 !important;
}

.splitLabel {
    height: auto !important;
}

.nonSplitInput {
    margin-bottom: 0 !important;
}

.boldText {
    font-weight: 700;
}

.boldText::after {
    content: "\00a0";
}

.accessGroupContainer {
    position: relative;
    max-width: none;
    min-width: none;
    width: 100%;
}

.closeButton {
    height: $spacing-4x;
    width: $spacing-4x;
    border-radius: $spacing-2x;
    background-color: $bg-light;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -40px;

    &:active {
        height: $spacing-4x;
        width: $spacing-4x;
        background-color: $bg-semidark;
    }
}

.slimSection {
    margin-left: 0 !important;
}

.ruleSection {
    margin-left: $spacing-3x;
}

.webAccessGroup:last-child {
    margin-bottom: $spacing-4x;
}

.rule {
    position: relative;
}

.ruleLabel {
    margin-left: $spacing-3x !important;
}

.buttons {
    display: flex;
    gap: 8px;
}

.container {
    padding: 24px;
}
