.children {
    display: grid;
    grid-template-columns: 65px 180px 1fr;
    column-gap: 25px;
    row-gap: 6px;
    line-height: 20px;
    padding: 20px;
}

.footer {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
}
