.container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.subtitle {
    padding-top: 8px;
}

.tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
}
