@import "../../../pre-v3/config.scss";

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg-lightest;
    height: 70px;
    padding: 0 $spacing-3x;
    border-top: 3px solid $brand;
    border-bottom: 2px solid $bg-light;
    flex-shrink: 0;
}

.links {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.link {
    padding: $spacing-1x $spacing-2x;
    font-size: $font-size;
    border: 0;
    border-radius: $border-radius-larger;
    background-color: transparent;
    cursor: pointer;
    color: $text-darkest;

    &:hover,
    &:active {
        background-color: $bg-light;
        color: $text-darkest;
    }

    &[disabled] {
        background-color: $bg-light;
        pointer-events: none;
        opacity: 0.65;
    }
}

.icon {
    font-size: $font-size-large;
    margin-right: $spacing-1x;
    color: inherit;
}
