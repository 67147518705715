@import "../../../styles.scss";

.actionBarDescription {
    margin-top: 8px;
    p {
        margin: 0;
    }
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 16px;
}

.cardContainer > * {
    flex: 1 1 auto;
}

.card {
    padding: 32px;
    box-shadow: 0px 0px 2px 2px #00000012 !important;
}

.title {
    font-weight: 700;
    @include regular-text;
}

.value {
    @include page-header-text;
    font-weight: 700;
}

.description {
    @include regular-text;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.outerContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
}

.upgrade {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.image {
    border-radius: 4px;
    margin-top: 24px;
    background-image: url("../../../images/card-background.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.compareButton {
    margin-top: 8px;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
