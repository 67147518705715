@import "../../config.scss";

.container {
    display: flex;

    & > header {
        display: flex;
        position: sticky;
        top: 0;
        height: fit-content;
        min-height: 100vh;
        z-index: $left-nav-bar-z-index;
    }

    & > main {
        flex: 1;
        overflow-y: auto;
        background-color: $background-sand;

        display: flex;
        flex-direction: column;
    }
}
