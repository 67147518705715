@import "../../config.scss";

.upsell {
    padding: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.heading {
    font-size: 24px;
}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.plans {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.planCard {
    border: 1px solid $bluegray-900;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
}

.cardTitle {
    font-family: "Libre Caslon Text", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.featuresTable {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.feature {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    gap: 24px;
    font-family: "Red Hat Display";
}

.featureName {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.featureDescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cardFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
