@import "../../../../../pre-v3/config.scss";

.form {
    margin-top: $spacing-2x;
}

.formInput {
    width: 100%;
}

.multiSelect {
    align-items: start !important;
}

.transferList {
    width: 75%;
}

.flex {
    display: flex;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}
