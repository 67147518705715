@import "../config.scss";

.deleteIcon {
    padding: 10px 13px;
    position: absolute;
    top: -9px;
    right: -$spacing-4x;
}

.orLabel {
    text-transform: uppercase;
    color: $highlight-primary;
    margin-right: $spacing-1x;
    font-weight: bold;
}

.hideOrLabel {
    visibility: hidden;
}

.inputForm {
    position: relative;
    left: 28px;

    button {
        padding: 11px 15px;
    }
}
