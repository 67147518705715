@import "../../../styles.scss";

.container {
    position: relative;
    top: 1px;
    display: inline;
}

.circle {
    @include small-bold-text;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    text-align: center;
    width: 20px;
    height: 20px;
    background: $bg-darkest;
    border-radius: 50%;
    color: $text-lightest;

    &.brand {
        background-color: $brand;
    }

    &.warning {
        background-color: $orange;
    }

    &.error {
        background-color: $error;
    }

    &.success {
        background-color: $success;
    }

    &.disabled {
        background-color: $text-dark;
    }

    &.loading {
        color: $text-dark;
        background-color: $bg-lightest;
    }
}
