.gridWithToolbar {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.header {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
}
