@import "../../../config.scss";

.mainContainer {
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    flex: 1;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.filterContainer {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.filterBar {
    flex: 1;
}

.gridContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.grid {
    flex: 1;
    min-height: 400px;
}

.eventsCount {
    margin-bottom: 16px;
    color: $bluegray-1000;
}

.json {
    padding: 16px 24px;
    height: 100%;
    overflow-y: auto;
}

.eventsGridCellWarning {
    border-left: 5px solid $feedback-warning-light !important;
}

.eventsGridCellError {
    border-left: 5px solid $feedback-error-light !important;
}
