@import "../../../../config.scss";

.container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 500px;
    padding-bottom: 24px;
}

.topContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
        @include h4-numeric-card-title;
    }
}

.endUserContainer {
    background-color: transparent;
    h2 {
        @include h4-numeric-card-title;
    }

    p {
        @include card-header-description-text;
    }

    .titleDescription {
        display: flex;
        gap: 8px;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cards {
    display: flex;
    gap: 24px;
    align-items: stretch;
}

.description {
    padding-bottom: 24px;

    display: flex;
    flex-direction: column;
    gap: 24px;
}

.errorBanner {
    a {
        color: $error-button-default;
        text-decoration-line: underline;

        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.otherInput {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.textArea {
    width: 100%;
    height: 100px;
    white-space: nowrap;
    resize: vertical;
}

.uploadFileButtonContainer {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.uploadFileButton {
    @include border-active;
    display: flex;
    align-items: center;
    height: 30px;
    width: 100px;
    padding: 8px;
    display: flex;
    gap: 8px;
}
