@import "../../../pre-v3/styles/grid.scss";

.filterButton {
    height: 40px;
    padding: 12px 15px;

    &:hover,
    &:active,
    &:focus {
        background-color: $bg-lighter;
    }
}

.menuButton {
    margin-right: auto;
    margin-left: 10px;
}

.nameLink {
    display: flex;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
}

.grid {
    height: 80vh !important;
}
