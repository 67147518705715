.toolbar {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.searchInput {
    width: 250px;
}

.grid {
    margin-top: 20px;
}
