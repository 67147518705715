@import "../../../../../styles/form.scss";

.formLabel {
    width: 200px;
}

.formButtons {
    display: flex;
    column-gap: 8px;
}

.buttons {
    display: flex;
}
