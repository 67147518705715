.flex {
    display: flex;
    justify-content: flex-end;
}

.accordionLabel {
    align-items: center;
}

.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
