@import "../../../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.errorBanner {
    margin-top: 16px;
}

.filterContainer {
    margin: 16px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.filterBar {
    flex: 1;
}

.grid {
    flex: 1;
}

.logCount {
    margin-bottom: 16px;
    color: $bluegray-1000;
}
