@import "../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: flex-start;

    div.protocol {
        width: 150px;
    }
}

.port {
    width: 80px;
}
