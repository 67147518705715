@import "../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-2x;
}

.content {
    width: 10px;
    flex-grow: 1;
}

.removeButton {
    align-self: center;
}
