@import "../../../pre-v3/styles/form.scss";
@import "../../config.scss";

.label {
    margin-bottom: $spacing-1x;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
}

.section {
    margin-bottom: $spacing-3x;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.section:nth-last-child(1) {
    margin-bottom: $spacing-4x;
}

.sectionList {
    flex-direction: column;
}

.inline {
    display: flex;
    flex-direction: row;
}

.inlineValue {
    flex-grow: 1;
    width: 10px;
}

.listValue {
    width: 100%;
}

.inlineLabel {
    margin-top: 12px;
}

.leftSpacing {
    margin-left: $spacing-6x !important;
}

.centerItems {
    align-items: center;
}

.description {
    font-style: italic;
    line-height: 1.4em;
    color: $text-light;
    font-size: $font-size-small;
    margin-bottom: $spacing-1x;
}
