.destinationRow {
    display: grid;
    grid-template-columns: 1.5fr minmax(100px, 0.4fr) 0.75fr 1.2fr;
    grid-gap: 16px;
    align-items: flex-start;
    padding-bottom: 8px;
}

.destinationField {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
    padding-left: 1px;
}

.ports {
    display: flex;
    flex-direction: row;
    gap: 16px;
    max-height: 40px;
}

.notes {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.deleteButton {
    max-height: 40px;
}

.formTextAres {
    resize: vertical;
}

.toggleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
