@import "../../../../../config.scss";
@import "../../../../../styles/form.scss";

.formLabel {
    width: 200px;
}

.deleteButton {
    margin-right: $spacing-1x;
    padding-left: $spacing-4x;
    padding-right: $spacing-4x;
}

.submitButton {
    padding-left: $spacing-4x;
    padding-right: $spacing-4x;
}

.buttons {
    display: flex;
    gap: 8px;
}
