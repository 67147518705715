@import "../../config.scss";

.banner {
    border: 1px solid;
    border-radius: 2px;
    padding: 15px;
    display: flex;
    flex-direction: row;
}

.bannerIcon {
    margin-right: 4px;
    position: relative;
    top: 1px;
}

.bannerContent {
    flex: 1;
}

.banner:global(.info) {
    border-color: $highlight-primary-darker;
    background-color: $highlight-primary-lighter;
    color: $highlight-primary-dark;
}

.banner:global(.warning) {
    border-color: $warning-dark;
    background-color: $warning-dark;
    color: $warning-darkest;
}

.banner:global(.error) {
    border-color: $error-dark;
    background-color: $error-light;
    color: $error-dark;
}

.banner:global(.success) {
    border-color: $success-dark;
    background-color: $success-lightest;
    color: $success-dark;
}
