@import "../../../../../config.scss";

.cardContainer {
    margin-top: $spacing-2x;

    .cardList {
        list-style: none;

        .cardListItem {
            display: inline-block;
            box-shadow: 0 0 4px 1px rgb(3 24 40 / 15%);
            border-radius: 8px;
            margin: $spacing-1x;

            &:hover {
                box-shadow: 0 0 4px 1px rgb(3 24 40 / 60%);
                cursor: pointer;
            }

            &.macOs {
                background: url("../../../../../../images/downloadmac.svg") no-repeat 0 0;
                background-size: 100%;
            }

            &.windows {
                background: url("../../../../../../images/downloadwindows.svg") no-repeat 0 0;
                background-size: 100%;
            }

            &.linux {
                background: url("../../../../../../images/downloadlinux.svg") no-repeat 0 0;
                background-size: 100%;
            }

            &.ios {
                background: url("../../../../../../images/downloadios.svg") no-repeat 0 0;
                background-size: 100%;
            }

            &.android {
                background: url("../../../../../../images/downloadandroid.svg") no-repeat 0 0;
                background-size: 100%;
            }

            .cardLink {
                display: block;
                padding: 68px 88px;
            }
        }
    }
}
