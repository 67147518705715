@import "../../../styles/grid.scss";

.filterButton {
    height: 100%;
}

.sectionContainer {
    padding: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
}

:global(.standardWebsite) {
    background-image: url("../../../../images/standardweb-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.databaseService) {
    background-image: url("../../../../images/db-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.sshService) {
    background-image: url("../../../../images/ssh-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.rdpService) {
    background-image: url("../../../../images/rdp-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.kubernetesService) {
    background-image: url("../../../../images/kubernetes-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.jsonTemplate) {
    background-image: url("../../../../images/customtmpl.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}

:global(.otherTCPService) {
    background-image: url("../../../../images/tcp-template.svg");
    background-repeat: no-repeat;
    background-position: $spacing-2x 50%;
}
