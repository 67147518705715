.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
