@import "../../../styles/form.scss";
@import "../../../config.scss";

.submitButton {
    margin-left: $spacing-1x;
}

.errorBanner {
    margin-bottom: $spacing-3x;
}

.buttons {
    display: flex;
    gap: 8px;
}
