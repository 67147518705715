@import "../../../config.scss";

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid $bluegray-800;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;

    > .title {
        @include h4-card-title;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}
