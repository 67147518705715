@import "../../../styles.scss";

.container {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: flex-start;
}

.formLabel {
    flex: 1;
}

.children {
    flex: 1;
}

.removeButton {
    align-self: center;
}
