@import "../../../config.scss";

.title {
    color: $text-darkest;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
}

.count {
    color: $text-darkest;
    font-size: 31px;
    font-weight: 600;
}
