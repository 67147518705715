@import "../../../styles.scss";

.container {
    @include border-dark-large;

    display: flex;
    flex-direction: column;
    row-gap: 8px;

    flex: 1;
    padding: 32px 32px 16px;
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
}
