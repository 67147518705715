@import "../../../styles.scss";

.rowContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;

    button.clearSelection {
        align-self: end;
        text-decoration-line: underline;
        border: 0;
        padding: 8px 0;
        color: $error;

        &:hover {
            color: $error-dark;
        }
    }
}

.multiSelectWrapper {
    position: relative;
    width: fit-content;
}

.fakeMultiSelectInput {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    z-index: -1;
}

.multiSearchableSelect {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.pillContainer {
    margin-top: 16px;
}

.removeLabel {
    margin-top: 8px;
    text-align: right;
    @include link-text;

    color: $error;
    cursor: pointer;
    align-self: flex-end;
}

.removeButton {
    align-self: center;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
}
