@import "../../../../styles.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include border-dark;
}

.pageBreak {
    margin: 0 0 24px;
}

.content {
    padding: 0 32px 16px;
}

.hideSwitch {
    [class*="Accordion_accordionSummarySwitchWrapper"] {
        display: none;
    }
}
