@import "../../config.scss";

.heading {
    color: $text-darkest;
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: 18px;
    overflow: hidden;
    overflow-wrap: break-word;
}
