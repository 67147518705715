@import "../../../pre-v3/config.scss";

.loader {
    margin: 0 $spacing-1x;
}

.center {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.medium {
    font-size: 3em;
}
