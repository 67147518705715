@import "../../config.scss";

.tooltipContainer {
    position: relative;
    top: 1px;
    margin: 0 4px;
}

.tooltip {
    max-width: 250px;
}

.tooltipIcon {
    font-size: $font-size-large;
    cursor: pointer;
}

.tooltipElement {
    font-size: 13px !important;
    padding: $spacing-1x $spacing-2x !important;
    background-color: $bluegray-900 !important;
}
