@use "../../../styles.scss";
@import "../../config.scss";

.iconButton {
    border: 0;
    border-radius: $border-radius-larger;
    background-color: transparent;
    padding: 10px 12px;
    font-size: $font-size-large;
    cursor: pointer;

    svg {
        position: relative;
        top: 1px;
    }

    &:hover {
        background-color: $bg-light;
    }

    &:focus {
        outline: 0;
    }

    &:active {
        background-color: $bg-light;
    }

    &[disabled] {
        background-color: $bg-light;
        pointer-events: none;
        opacity: 0.65;
    }
}

.iconButtonPrimary {
    background-color: $highlight-primary;
    color: $text-lightest;

    &:hover {
        background-color: $highlight-primary-dark;
    }

    &:active {
        background-color: $highlight-primary-darker;
    }

    &[disabled] {
        background-color: $highlight-primary-dark;
        opacity: 0.6;
    }
}

.iconButtonBrand {
    background-color: styles.$brand;
    color: styles.$text-lightest;

    &:hover {
        background-color: styles.$brand-dark;
    }

    &:active {
        background-color: styles.$brand;
    }

    &[disabled] {
        background-color: styles.$bg-light;
        color: styles.$text-dark;
        border-color: styles.$border-dark;
        border: solid 1px;
    }
}

.iconButtonWithText {
    padding: 10px $spacing-2x;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.textLabel {
    font-size: $font-size-small;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-large;
    font-weight: 500;
}

.iconSpacing {
    margin-left: 10px;
}
