@import "../../../config.scss";
@import "../../../styles/overview.scss";

.policy {
    border: 1px solid $border-dark;
    border-radius: $border-radius;
    background-color: $bg-lighter;
    padding: 18px 16px;
    margin-bottom: $spacing-2x;
    width: 90%;

    td {
        padding: 4px;
        font-weight: 500;
    }
}

.statusIcon {
    font-size: 20px;
    margin-left: 2px;
    margin-right: $spacing-1x;
    position: relative;
    top: -1px;
}

.policyEnforcing {
    background-color: $success-lightest;
    border-color: $success-dark;

    .statusIcon {
        color: $success-dark;
    }
}

.policyPermissive {
    background-color: $warning-lightest;
    border-color: $warning-dark;

    .statusIcon {
        color: $warning-dark;
    }
}

.noPolicy {
    background-color: $error-light;
    border-color: $error-dark;

    .statusIcon {
        color: $error-dark;
    }
}

.statusHeader {
    color: $text-semidark;
    font-weight: 500;
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

.statusText {
    font-weight: 600;
    font-size: $font-size-large;
}

.policyTable {
    margin-top: $spacing-3x;
}

.serviceSpecContainer {
    padding-top: $spacing-2x;
    padding-bottom: $spacing-4x;
    padding-right: $spacing-2x;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.section {
    width: 100%;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
