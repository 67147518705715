@import "../../../../config.scss";
@import "../../../../styles/form.scss";

.verticalSpacing {
    margin-top: $spacing-4x;
}

.selectContainer {
    margin-top: $spacing-2x;
    width: 90%;
}

.port {
    display: block;
    margin-top: $spacing-2x !important;
    width: 100px;
}
