@import "../../../../../config.scss";
@import "../../../../../styles/form.scss";

.formLabel {
    width: 200px;
}

.saveButton {
    width: 100%;
}
