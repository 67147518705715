@import "../../styles.scss";
@import "../../config.scss";

.container {
    display: flex;

    &:focus-within {
        outline: none;
        @include border-active;
        .input {
            border: none;
        }
        .button {
            border: none;
        }
    }

    .input {
        flex: 1;
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        outline: none;
    }

    .button {
        padding: 0px 16px;
        @include border-inactive;

        border-radius: 4px;
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background-color: transparent;
        cursor: pointer;
    }
    .button:focus-within {
        outline: none;
        @include border-active;
        border-top: none;
        border-bottom: none;
        border-right: none;
    }

    .icon {
        display: flex;
        justify-content: center;
    }

    .input:disabled + .button {
        @include border-disabled;
        background-color: $gray-300;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
