@import "../../../styles.scss";

.DateTimePicker {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dateRangeSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    span {
        position: relative;
        top: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.singleDate,
.rangeOfDates {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.timeRangeSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 60%;
}

.sectionDivider {
    height: 1px;
    width: calc(100% + #{32px * 2});
    background-color: $border-dark;
    margin: 32px 0;
    position: relative;
    left: -32px;
}

.confirmButton {
    width: 50px;
    height: 40px;
}

.filterFooter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
