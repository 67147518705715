.children {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 600px;
}

.footer {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
}

.checkBox {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    row-gap: 16px;
}
