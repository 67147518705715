@import "../../../../../styles.scss";

.container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 48px;
    row-gap: 16px;
}

.search {
    width: 250px;
}

.nameContainer {
    display: flex;
}

.accordionContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.table {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.card {
    flex: 40%;
    padding: 20px 21px;
}

.title {
    @include regular-text;
    color: $text-dark !important;
}

.value {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.description {
    @include italic-text;
    color: $text-dark !important;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.outerContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
}

.iconColor {
    color: $success;
    margin-right: 4px;
}

.compareButton {
    margin-top: 24px;
}

.protected {
    background-color: $success-light !important;
    color: $success !important;
    border-color: $success !important;
    pointer-events: none;
}

.pillContainer {
    border: none;
    padding: 0;
    flex-wrap: wrap;
}

.securityAction {
    margin-top: 32px;
    margin-bottom: 24px;
}

.icon {
    font-size: $icon-size-normal;
}

.helpfulLinks {
    padding-top: 24px;
}

.serviceTunnelContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.selectInput {
    width: 500px;
}

.infoBanner {
    width: 500px;
    align-items: center;
    margin-top: 16px;
}

.toggleButton {
    align-items: center;
}

.gridContainer {
    width: 100%;
    height: 100%;
}

.appText {
    p {
        margin: 0;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
