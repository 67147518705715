@import "../../../config.scss";
@import "../../config.scss";

.widgetContainer {
    background-color: $background-white;
    padding: $spacing-2x $spacing-2x $spacing-2x $spacing-3x;
    border: 1px solid $border-light;
    box-shadow: 0 0 4px 1px rgb(3 24 40 / 15%);
    border-radius: $border-radius-large;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.countTitle {
    color: $text-darkest;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.5em;
    margin-bottom: 4px;
}

.countValue {
    font-size: 31px;
    font-weight: 600;
    color: $black;
}

.noDataOverlay {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
