@import "../../../styles.scss";

.iconButton {
    padding-left: 16px;
    padding-right: 16px;
}

.action {
    border: none !important;
    background-color: transparent !important;
}
