@import "../../../../../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-2x;
}

.platformIcon {
    color: $text-light-v3;
}

.versionSelectContainer {
    display: flex;
    align-items: center;
    column-gap: $spacing-1x;
}

.versionLabel {
    display: flex;
    column-gap: $spacing-1x;
}

.versionSelect {
    width: 130px;
}
