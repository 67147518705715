@import "../../config.scss";
@import "../../../pre-v3/styles/form.scss";

.container,
.containerDisabled {
    display: grid;
    grid-gap: $spacing-1x;
}

.container {
    grid-template-columns: 1fr 1fr 50px;
}

.containerDisabled {
    grid-template-columns: 1fr 1fr;
}

.header {
    padding-bottom: $spacing-1x;
}

.input {
    min-width: 0;
}
