@import "../../../../../../../../../pre-v3/config.scss";

.addFileButton {
    align-self: flex-start;
}

.createFileButton {
    display: flex;
    align-items: center;
    column-gap: $spacing-1x;
}
