@import "../../config.scss";

.toggleButtonContainer {
    border: 1px solid $border-light;
    border-radius: $border-radius;
    display: inline-block;
    overflow: hidden;
}

.disabled {
    .toggleButton:hover {
        background-color: $bg-lightest;
    }

    .toggleButtonActive:hover {
        background-color: $bg-semidark;
    }
}

.toggleButton {
    background-color: $bg-lightest;
    color: $text-semidark;
    padding: 11px 16px;
    border: 0;
    cursor: pointer;

    &:not(:last-child) {
        border-right: 1px solid $border-light;
    }

    &:hover {
        background-color: $bg-lighter;
    }

    &:focus {
        outline: 0;
        color: $text-dark;
    }

    &:active {
        background-color: $bg-semidark;
        color: $text-darkest;
    }
}

.toggleButtonActive {
    background-color: $bg-semidark;
    color: $text-darkest;

    &:hover {
        background-color: $bg-semidark;
    }

    &:focus {
        color: text-dark;
    }

    &:active {
        background-color: $bg-semidark;
        color: $text-darkest;
    }
}
