@import "../../../../pre-v3/config.scss";
@import "../../../../pre-v3/styles/page.scss";
@import "../../../../pre-v3/styles/grid.scss";

.headerSection {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-3x;
}

.add {
    align-items: center;
    display: flex;
    gap: $spacing-1x;
    border-radius: $border-radius-larger;
    letter-spacing: 0.5px;
    font-size: $font-size-small;
    font-weight: 500;
    text-transform: uppercase;
}

.addIcon {
    font-size: $font-size-large;
}

.orgName {
    display: flex;
    align-items: center;
    gap: 5px;
}
