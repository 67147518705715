@import "../../../../config.scss";
@import "../../../../styles/form.scss";
@import "../../../../styles/input-list.scss";

.hosts {
    margin-top: $spacing-4x;
}

.backendLabel {
    margin-top: $spacing-3x;
    margin-bottom: $spacing-2x;
}

.domainInput,
.portInput {
    margin-right: $spacing-1x;
    margin-bottom: $spacing-1x;
}

.domainInput {
    width: calc(100% - 180px);
}

.portInput {
    width: 80px;
}

.checkbox {
    margin-left: $spacing-3x !important;
    margin-right: $spacing-half !important;
}

.checkboxLabel {
    font-weight: normal;
    color: $text-semidark;
}

.disabled {
    display: none;
}

.inputContainer {
    margin-top: $spacing-2x;
    width: 90%;
}
