@import "../../../config.scss";

.description {
    color: $bluegray-1000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.destinationTable {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.destinationListHeader {
    display: grid;
    grid-template-columns: 1.5fr minmax(100px, 0.4fr) 0.75fr 1.2fr;
    grid-gap: 16px;
    align-items: flex-start;
    text-transform: uppercase;
    border-bottom: 1px solid $bluegray-400;
    padding-bottom: 8px;
}

.footer {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
}
