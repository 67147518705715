@import "../../../../config.scss";

.filterButton {
    height: 40px;
    padding: 12px 15px;

    &:hover,
    &:active,
    &:focus {
        background-color: $bg-lighter;
    }
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
}

.sectionContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
}

.gridContainer {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.menuButton {
    margin-right: auto;
    margin-left: 10px;
}
