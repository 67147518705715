@import "../../../styles.scss";

.iconButton {
    border-radius: 24px;
}

.noLabel {
    width: 35px;
    height: 35px;
    padding: 0 !important;
    align-items: center;
}

.leftLabel,
.rightLabel {
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    cursor: pointer;
}

.icon {
    width: 12px;
    height: 14px;
}
