@import "../../../styles.scss";

.pillsContainer {
    @include border-dark-large;

    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
}
