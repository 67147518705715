@import "../../../../styles.scss";

.form {
    padding-bottom: 32px;
}

.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 16px;
}

.bottomPadding {
    padding-bottom: 16px;
}

.addAttributeButton {
    padding: 16px;
    border-style: dashed;
}
