.container {
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
