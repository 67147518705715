@import "../../config.scss";

.navigationTabBar {
    ul {
        display: flex;
        column-gap: 24px;
        width: 100%;
    }

    li {
        list-style: none;
        width: 100%;
    }

    .tab {
        color: $bluegray-900;
        font-family: $font-family-serif;
        font-weight: 600;
        font-size: 14px;
        border-bottom: 2px solid $bluegray-700;
        padding: 12px 8px 12px 0px;
        width: 100%;
        display: block;
        white-space: nowrap;

        &::before {
            content: "●";
            margin: 0 7px 0 2px;
            visibility: hidden;
        }
        &[aria-current="page"] {
            &::before {
                visibility: visible;
            }
        }

        &[aria-current="page"],
        &:hover {
            color: $text-darkest;
            border-bottom: 2px solid $text-darkest;
        }
    }
}
