@import "../../../../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 $spacing-3x;
}

.errorBanner {
    margin-top: $spacing-2x;
}

.input {
    width: 250px;
    margin-top: 20px;
}

.grid {
    margin-top: 20px;
}

.completeMigrationBanner {
    p {
        margin: 0;
    }
}

.migrateButton {
    align-self: flex-start;
    margin: $spacing-2x 0;
}
