.container {
    width: 100vw;
    max-width: 628px;
}

.text {
    margin-bottom: 20px;
}

.inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

.input {
    width: 100%;
}

.buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}
