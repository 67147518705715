.appText {
    p {
        margin: 0;
    }
}

.alignLeft {
    align-self: flex-start;
}

.alignRight {
    align-self: flex-end;
}
