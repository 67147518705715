@import "../../../pre-v3/config.scss";

.cell {
    line-height: normal;
}

.icon {
    position: unset;
    top: unset;
    margin-left: 4px;
}

.image {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
