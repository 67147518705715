@import "../config.scss";

.ag-icon-contracted,
.ag-icon-expanded {
    position: relative;
    top: -2px;
    cursor: pointer;
}

.ag-header-cell .ag-icon {
    position: relative;
    top: -1px;
}

.ag-full-width-row {
    overflow: auto;
}

.ag-body-horizontal-scroll {
    display: none;
}

.ag-center-cols-viewport {
    overflow-x: hidden;
}

.ag-paging-row-summary-panel {
    display: none;
}

.ag-theme-material {
    font-size: $font-size;
    color: $black;

    a {
        font-weight: 500;
    }
}

.ag-theme-material .ag-row {
    white-space: normal;
    border-color: $border-light;

    &:hover,
    &:active {
        background-color: $bg-lighter;
    }
}

.ag-theme-material .ag-row-selected {
    background-color: $bg-lighter;
}

.ag-theme-material .ag-header {
    border-bottom: 1px solid $border-light;
}

.ag-theme-material .ag-header-cell {
    color: $text-light;

    &:hover {
        background-color: $bg-lighter !important;
    }
}

.ag-theme-material .ag-header-cell-text {
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing-large;
    font-weight: normal;
    line-height: 30px;
    text-transform: uppercase;
    padding: 0;
}

.ag-theme-material .ag-cell {
    line-height: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ag-theme-material .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0;
}

.ag-theme-material .ag-cell-autoheight {
    white-space: pre-line;
}

.ag-theme-material .ag-ltr .ag-has-focus .ag-cell-focus {
    border-color: transparent;
}

.ag-theme-material .ag-has-focus .ag-row-focus {
    background-color: $bg-light;
}

.ag-group-value {
    vertical-align: top;
}

.ag-group-value .ag-react-container {
    display: inline;
}

.ag-cell-wrapper.ag-row-group {
    align-items: center;
}

.ag-cell {
    user-select: initial;
    -moz-user-select: text;
    -ms-user-select: text;
    -webkit-user-select: text;
}

.ag-cell-wrapper {
    min-height: 22px;

    &.ag-cell-expandable {
        height: 22px;
    }
}

.ag-cell-value {
    min-height: 15px;
}

.ag-theme-material .ag-paging-panel {
    border-color: $border-light;
    color: $text-light;
}

.ag-theme-material .ag-drag-handle .ag-icon {
    &,
    &:focus,
    &::after,
    &::before {
        user-select: none;
    }
}
