@import "../../config.scss";

.actionText {
    margin-top: $spacing-2x;
    font-size: $font-size-large;
}

.optionContainer {
    display: block;
    background-color: $bg-lightest !important;
    text-align: left;
    padding: $spacing-2x;
    padding-left: 85px;
    margin: $spacing-2x 0;
    border: 2px solid $border-light !important;
    border-radius: $border-radius !important;
    width: 100%;
    position: relative;
    overflow: hidden;

    &:not(:disabled):hover {
        cursor: pointer;
        border-color: $brand !important;
        background-color: $brand-lightest !important;
    }
}

.optionHeader {
    font-weight: 500;
    margin: 0 0 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.optionText {
    color: $text-light;
    margin: 0;
}

.optionContainer:disabled .optionText {
    color: $bluegray-200;
}

.closeButton {
    font-size: $font-size-larger;
    margin-right: $spacing-2x;
    color: $text-light;

    &:hover {
        color: $text-dark;
        background-color: transparent;
    }
}
