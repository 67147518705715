@import "../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-2x;
}

.title {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 260%;
    color: $text-light-v3;
}

.grid {
    &:global(.ag-theme-material) {
        :global(.ag-center-cols-clipper) {
            min-height: unset;
        }
    }
}

.noUsersBanner {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: $spacing-2x;

    p {
        margin: 0;
    }
}
