@import "../../../pre-v3/config.scss";

.icon {
    width: 14px !important;
}

.success {
    color: $success-dark;
}

.header {
    display: flex;
    flex-direction: row;
    background: $white;
    padding: 10px 15px;
    margin-bottom: 0;
    border-radius: 5px 5px 0 0;
    border: 1px solid $brand-dark;
}

.label {
    font-size: $font-size-smaller;
    text-transform: uppercase;
}

.content {
    background: $brand-dark;
    font-size: $font-size-small;
    color: $bg-lightest;
    padding: 10px 15px;
    margin-bottom: 0;
    border-radius: 0 0 5px 5px;
    overflow-x: auto;
}

.button {
    padding: 0;
    margin-left: auto;
    cursor: pointer;
    font-size: $font-size-small;
    border: $white;

    &:hover {
        background-color: $white;
    }
}
