.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}

.heading {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.menuButton {
    margin-right: auto;
    margin-left: 10px;
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

.leftTools {
    display: flex;
    column-gap: 16px;
}

.search {
    width: 250px;
}

.pillContainer {
    border: none;
    padding: 0;
    flex-wrap: nowrap;
}

.nameContainer {
    display: flex;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
