@import "../../../../../pre-v3/config.scss";

.configurationContainer {
    display: grid;
    align-items: end;
    grid-template-columns: auto 1fr;
    column-gap: $spacing-2x;
    row-gap: $spacing-3x;
}

.platformIcon {
    font-size: 20px;
    color: $text-light-v3;
}

.platformLabel {
    font-size: $font-size-large;
    font-weight: 600;
    color: $text-v3;
}

.configurationInputs {
    grid-column-start: 2;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-2x;
}

.doubleFlex {
    flex: 2;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
