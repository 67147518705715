@import "../../../../../../../../../pre-v3/config.scss";

.multiSelectPill {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4x;
}

.menuContainer {
    align-self: flex-start;
}

.newOptionButton {
    align-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: $spacing-1x;
}
