@import "../../../../config.scss";

.description {
    margin: $spacing-4x 0;
    font-weight: 500;
}

.osName {
    margin-top: $spacing-2x;
    margin-bottom: $spacing-1x;
}

.osSetting {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.osSelect {
    flex: 1;
    flex-basis: 400px;
    flex-shrink: 0;
    margin-bottom: $spacing-2x;
}

.osList {
    flex: 1;
    flex-basis: 400px;
    flex-shrink: 0;
    color: $text-light;
    font-style: italic;
    margin-left: $spacing-4x;
}

.saveButton {
    margin-top: $spacing-1x;
    width: 150px;
}
