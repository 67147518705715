@import "../../config.scss";

.navLinkContainer,
.navLink {
    list-style: none;
    margin: 0;
    padding: 0;
}

.userMenuDivider {
    margin: 5px 0;
    border-color: $border-dark;
}

.buttonLink {
    white-space: nowrap;
    padding: 10px $spacing-1x !important;
    color: $bg-light !important;
    font-size: $font-size-small;
    letter-spacing: $letter-spacing;
}

.userMenu {
    cursor: pointer;
    border-bottom: 5px solid transparent;

    &:hover {
        border-bottom-color: $brand;
    }
}
