.formSubmit {
    width: 20%;
    min-width: 100px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inputLabel {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-top: 24px;
}

.input {
    width: 64px;
}
