@import "../../config.scss";
@import "../../../config.scss";

.icon {
    width: 14px !important;
}

.success {
    color: $success-dark;
}
