@import "../../../../../../pre-v3/config.scss";

.content {
    display: flex;
}

.left {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-1x;
    margin-right: $spacing-2x;

    p {
        margin: 0;
    }
}

.right {
    max-width: 300px;
    min-height: 100px;
    padding-left: $spacing-2x;
    border-left: 1px solid #cccfdb;
}
