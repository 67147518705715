@import "../../../../styles.scss";

.appDetailsModal {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    min-height: 400px;
}

.appDetail {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
}

.appIcon {
    width: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageIcon {
        width: 100%;
        height: 100%;
    }
}

.appDetailHeader {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-weight: 400;
    min-width: 600px;
    max-width: 600px;
}

.appDetailTitle {
    font-size: 18px;
    font-weight: 800;

    a {
        color: $brand-dark;
        display: flex;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;

        .appLink {
            color: $text-dark;
        }
    }

    a:hover {
        color: $brand;
    }
}

.appDetailDescription {
    font-size: 14px;
    color: $text-dark;
}

.appDetailMeta {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-left: 64px;

    .metaRow {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        align-items: center;
    }

    .title {
        color: $text-dark;
        min-width: 100px;
        font-size: 12px;
        flex-shrink: 0;
        text-transform: uppercase;
    }
    .value {
        font-size: 14px;
        color: $text-darkest;
    }
}

.appDetailSensitivity {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    row-gap: 8px;
    flex-wrap: wrap;
}

.appDetailHelpfulLinks {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.comma {
    margin-right: 4px;
}

.appDetailTables {
    display: flex;
    flex-direction: row;
    flex: 0 0 50%;
    width: 100%;
    column-gap: 24px;
    padding-top: 14px;
    max-height: 350px;
}

.appDetailsFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.modalHeight {
    height: 300px;
}
