@import "../../../pre-v3/config.scss";

.simpleTable {
    width: 100%;

    td {
        overflow: hidden;
        padding: 7px 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.label {
    color: $text-semidark;
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing;
    width: 125px;
    text-transform: uppercase;
}

.valueCell {
    max-width: 0;
    font-size: $font-size;
}
