@import "../../config.scss";
@import "../../../config.scss";

.container {
    padding: 20px 30px 0 65px;
}

.text {
    @include code-text-active;
    line-height: 1.5;
}
