.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.showOnTop {
    order: -1;
}
