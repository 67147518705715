.buttonCell {
    display: flex;
    align-items: center;
}

.adminBar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}

.overviewContainer {
    margin-top: 24px;
    margin-bottom: 24px;
}
