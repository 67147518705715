@import "../../../styles.scss";

.statusCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.statusIcon {
    font-size: 20px;
}

.statusIconEnforcing {
    color: $success;
}

.statusIconPermissive {
    color: $warning;
}

.statusIconNoPolicy {
    color: $error;
}

.statusLabel {
    font-weight: 600;
}
