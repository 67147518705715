@import "../../../../config.scss";
@import "../../../../styles/form.scss";

.verticalSpacing {
    margin-top: $spacing-4x;
}

.formTextArea {
    white-space: normal;
}
