.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
}

.sectionContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}
