.search {
    width: 250px;
}

.outerContainer {
    height: 100%;
    padding: 0 24px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.container {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}

.iconButton {
    margin-left: auto;
}

.errorBanner {
    margin-top: 16px;
}
