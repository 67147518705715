@import "../../config.scss";

.navMenuButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: $text-lighter;
    height: 100%;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: $letter-spacing;
    cursor: pointer;

    &:hover {
        color: $text-lightest;
    }

    &:focus {
        color: $text-lighter;
        text-decoration: none;
        outline: none;
    }
}

.highlight {
    border-bottom: 5px solid transparent;

    &:hover {
        border-bottom-color: $brand;
    }
}

.caretDown {
    margin-left: 6px;
    display: inline-block;
    padding-bottom: $spacing-2x;
}

.navMenu {
    background-color: $bg-lightest;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
