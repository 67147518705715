@import "../../config.scss";

.toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.25);
}

.ToastViewport {
    --flouting-toast-width: 521px;
    --toast-padding-right: 16px;
    position: fixed;
    bottom: 24px;
    right: var(--toast-padding-right);
    width: var(--flouting-toast-width);
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
}

.floating {
    box-shadow: -6px 8px 7px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    width: var(--flouting-toast-width);
}
.floating[data-state="open"] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.floating[data-state="closed"] {
    animation: hide 100ms ease-in;
}
.floating[data-swipe="move"] {
    transform: translateX(0);
}
.floating[data-swipe="cancel"] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
}
.floating[data-swipe="end"] {
    animation: swipeOut 100ms ease-out;
}

@keyframes hide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(calc(100% + var(--toast-padding-right)));
    }
    to {
        transform: translateX(0);
    }
}

@keyframes swipeOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(100% + var(--toast-padding-right)));
    }
}

.group {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.actionButton {
    padding: 8px 16px;
    border-radius: 30px;
    color: $text-white;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
}

.close {
    border: none;
    background-color: transparent;
    color: inherit;
}

.warning {
    background-color: $feedback-warning-light;
    color: $feedback-warning-dark;
    .actionButton {
        background-color: $feedback-warning-dark;
        border: solid 1px $feedback-warning-dark;
    }
}

.information {
    background-color: $feedback-info-light;
    color: $feedback-info-dark;
    .actionButton {
        background-color: $feedback-info-dark;
        border: solid 1px $feedback-info-dark;
    }
}

.genericPrimary {
    background-color: $feedback-generic-1-light;
    color: $feedback-generic-1-dark;
    .actionButton {
        background-color: $feedback-generic-1-dark;
        border: solid 1px $feedback-generic-1-dark;
    }
}

.genericSecondary {
    background-color: $feedback-generic-2-light;
    color: $feedback-generic-2-dark;
    .actionButton {
        background-color: $feedback-generic-2-dark;
        border: solid 1px $feedback-generic-2-dark;
    }
}

.error {
    background-color: $feedback-error-light;
    color: $feedback-error-dark;
    .actionButton {
        background-color: $feedback-error-dark;
        border: solid 1px $feedback-error-dark;
    }
}

.success {
    background-color: $feedback-success-light;
    color: $feedback-success-dark;
    .actionButton {
        background-color: $feedback-success-dark;
        border: solid 1px $feedback-success-dark;
    }
}
