.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px 24px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
