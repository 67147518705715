@import "../../../styles.scss";

.outside {
    @include border-dark-large;

    padding: 32px;
    position: relative;
}

.label {
    @include small-uppercase-text;

    position: absolute;
    background-color: $bg-lightest;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    gap: 8px;
    left: 24px;
    top: -8px;
}

.inside {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
