.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
}

.description {
    padding: 8px 0;
}
