@import "../../../styles/form.scss";
@import "../../../styles/dynamic-form.scss";
@import "../../../config.scss";

.label {
    display: block;
    width: 100%;
    margin-top: $spacing-2x;
    margin-bottom: $spacing-2x;
}

.advancedSection {
    padding-bottom: $spacing-half;
}

.input {
    width: 100%;
}
