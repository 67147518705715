.loader {
    width: 500px;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 553px;
    ul {
        scrollbar-gutter: auto;

        /* width */
        &::-webkit-scrollbar {
            width: 8px;
            left: -100px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #8793af;
            border-radius: 20px;
        }
    }
    li {
        list-style: none;
        padding: 10px 12px;
        border-bottom: 1px solid #e2e8f4;
    }
}
.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
