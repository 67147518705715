@import "../../../../../../../pre-v3/config.scss";

.container {
    margin: 0 $spacing-3x;
    padding: $spacing-3x $spacing-4x;
    background: $bg-darkest;
    border-radius: 6px;

    h2 {
        color: $brand;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-top: 0;
    }

    a,
    p {
        color: $text-lightest;
    }

    p {
        margin-top: $spacing-2x;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
}

.link {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
}
