.statusContainer {
    flex: 0 0 400px;
}

.errors {
    padding: 8px 24px 8px 24px;
}

.tabContainer {
    position: relative;
    display: flex;
}

.tabs {
    width: 100%;

    form {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.editButton {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
}

.actionButtons {
    display: flex;
    gap: 8px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
