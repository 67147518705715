@import "../../../pre-v3/config.scss";

.tooltipElement {
    font-size: 13px !important;
    line-height: 1.5;
    padding: $spacing-1x $spacing-2x !important;
    background-color: $bluegray-900 !important;
    white-space: pre-line;
}

.tooltip {
    max-width: 250px;
}
