@import "../../../config.scss";

.list {
    list-style: none;
}

.item {
    margin-bottom: 8px;
}

.collision {
    color: $error-button-default;
}
