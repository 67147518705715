@import "../../../../../../../../pre-v3/config.scss";
@import "../../../../../../../../config.scss";

.logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-white;
    border: 2px solid $gray-400;
    width: 75px;
    height: 65px;
    border-radius: $border-radius;
}

.logo {
    width: 38px;
    height: 42px;
}
