@import "../../../../config.scss";
@import "../../../../styles/page.scss";
@import "../../../../styles/dynamic-form.scss";

.enforcementOptions {
    margin-left: 25px;
}

.label {
    width: 700px;
    font-weight: normal;
    text-align: left;
}

.statusButton {
    margin-right: $spacing-1x;
}

.cancelButton {
    margin-right: $spacing-1x;
}

.buttons {
    margin-top: $spacing-4x;
    display: flex;
    gap: 8px;
}
