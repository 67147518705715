@import "../../../../styles.scss";

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@mixin buttons {
    display: flex;
    column-gap: 8px;
}

.left {
    @include buttons;

    justify-self: start;
}

.right {
    @include buttons;

    justify-self: end;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 32px 0 0;
    border-top: 1px solid $border-dark;
    gap: 16px;
}
