@import "../../../../config.scss";
@import "../../../../styles/form.scss";
@import "../../../../styles/input-list.scss";

.redirectLabel {
    margin-top: $spacing-2x;
}

.switch {
    position: relative;
    top: 12px;
}

.nameIdPassthrough {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 48px;
    padding-right: $spacing-4x;
    color: $text-semidark;
}
