@import "../../../config.scss";

.container {
    .paper {
        border-radius: $spacing-1x;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    border-bottom: 1px solid $border-lighter;

    h6 {
        font-size: $font-size-h6;
        font-weight: 300;
        color: $text-dark;
    }
}

.closeIcon {
    display: flex;
    margin-right: $spacing-2x;
    padding: 10px;

    svg {
        width: 1em;
    }
}

.actions {
    margin: 0;
    padding: $spacing-2x $spacing-1x;
    border-top: 1px solid $border-light;
    background-color: $bg-lightest;
}
