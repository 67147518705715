@import "../../config.scss";

.cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    padding: 40px;
}

.cardContainer > * {
    flex: 1 1 auto;
}

.title {
    @include card-large-body-text;
}

.description {
    color: $text-darkest !important;
    @include form-field-info-help-text;
    width: 233px;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.outerContainer {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
}

.icon {
    font-size: 44px;
    margin-top: 16px;
    color: $bluegray-700;
}

.card {
    border: 1px solid $bluegray-800;
    display: flex;
    flex-direction: column;
    max-width: 490px;
    height: 210px;
    border-radius: 12px !important;
    padding: 32px;
}

.buttonContainer {
    margin-top: auto;
}

.buttonContainer button {
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 8px;
    margin-left: auto;
}
