@import "../../../config.scss";

.connectorsTable {
    :global(.ag-root-wrapper),
    :global(.ag-header),
    :global(.ag-row) {
        background-color: transparent;
    }
}

.heading {
    color: $bluegray-1200;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
}
