@import "../../config.scss";
@import "../../styles.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    font-weight: 600;
    color: $text-darkest;
}

.statusIcon {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
}

.success {
    color: $success;
}

.warning {
    color: $warning;
}

.error {
    color: $error;
}

.disabled {
    color: $text-dark;
}

.unknown {
    color: $text-darkest;
}
