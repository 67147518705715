@import "../../../config.scss";

.container {
    .paper {
        border-radius: $spacing-1x;
    }
}

.dialogActions {
    padding: 10px 15px;

    button {
        margin-left: 10px;
    }
}
