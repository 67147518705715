@import "../../../styles.scss";

.pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    border-radius: 24px;
    padding: 4px 16px;
    cursor: pointer;

    & button {
        cursor: pointer;
    }

    &.error {
        background-color: $bg-lightest;
        color: $error;
        border-color: $error;

        &:hover {
            background-color: $error-lightest;
            color: $error;
            border-color: $error;
        }
    }

    &.disabled {
        background-color: $bg-light;
        color: $text-dark;
        border-color: $border-dark;
        pointer-events: none;
    }

    &.notClickable {
        &:hover {
            background-color: $bg-lightest;
            color: $text-darkest;
            border-color: $border-dark;
            cursor: default;
        }

        .button:first-child {
            cursor: default;
        }
    }
}

.button {
    display: flex;
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    color: inherit;
}
