@import "../../../../../styles/form.scss";
@import "../../../../../config.scss";

.formLabel {
    width: 200px;
}

.deleteButton {
    margin-right: $spacing-1x;
}

.secretLabel {
    display: flex;
    flex-direction: row;
}

.copyButton {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.buttons {
    display: flex;
    gap: 8px;
}
