@import "../../config.scss";

%nav-button {
    display: inline-block;
    vertical-align: top;
    padding-top: 16px;
    margin: 0 15px;
}

.navBar,
.navButton {
    list-style: none;
    height: 100%;
    padding-inline-start: 0;
}

.navBarDivider {
    display: inline-block;
    vertical-align: top;
    width: 1px;
    height: 100%;
    border-left: 1px solid $border-darker;
    margin: 0 10px;
}

.navButton {
    @extend %nav-button;
}

.navButtonHome {
    padding: 0 10px;
}

.navButtonMore {
    @extend %nav-button;

    cursor: pointer;
    color: $text-lighter;

    &:hover {
        color: $text-lightest;
        border-bottom: 5px solid $brand;
    }
}

.moreLinkContainer,
.moreLink {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moreLink {
    border-left: 5px solid $bg-lightest;

    &:hover {
        border-left: 5px solid $brand;
    }
}
