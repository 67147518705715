@import "../../../config.scss";
@import "../../../styles/form.scss";

.container {
    display: grid;
    grid-template-columns: 65px 180px 1fr;
    column-gap: 25px;
    row-gap: 6px;
    line-height: 20px;
    padding: 20px;
}

.header {
    text-transform: uppercase;
    font-size: 12px;
    min-width: unset;
    width: unset;
    font-weight: 500;
    margin: 0;
    margin-bottom: 9px;
}

.statusCell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 4px;
}

.bold {
    font-weight: 600;
    color: $bluegray-800;
}

.emailContainer {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.email {
    width: 350px;
}
