@import "../../config.scss";

.table {
    border-collapse: collapse;
    max-width: 750px;

    td {
        padding: 8px;
        width: 50%;
    }

    th {
        text-align: left;
        color: $text-light;
        font-size: $font-size-small;
        letter-spacing: 0.5px;
        font-weight: normal;
        line-height: 30px;
        text-transform: uppercase;
        padding: 8px;
    }

    tr {
        background-color: $bg-lightest;
        border-bottom: 1px solid $border-light;
        line-height: 30px;
    }
}

.noData {
    text-align: center;
}
