.form {
    width: 100vw;
    max-width: 823px;
}

.checkboxes {
    display: flex;
    gap: 38px;
}

.buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}
