.container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.checkboxes {
    display: flex;
    column-gap: 16px;
}
