@import "../../../config.scss";

.card {
    border-radius: 12px;
    border: 1px solid $bluegray-800;
    padding: 24px;
    row-gap: 24px;
    display: flex;
    flex-direction: column;
}

.cardTopHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleSide {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.heading {
    @include h4-card-title;
}
