@import "../../styles.scss";
@import "../../config.scss";

.container {
    display: flex;
    overflow: hidden;

    .action {
        background-color: transparent;
        border: none;
        padding-right: 16px;
        padding-left: 8px;

        &:disabled {
            svg {
                fill: $gray-700;
            }
        }
    }
}
