.container {
    position: relative;
}

.loading {
    padding: 10px;
}
.card {
    position: absolute;
    width: 100%;
    max-height: 70px;
    overflow: hidden;
    overflow-y: auto;
}
.item {
    list-style: none;

    button {
        cursor: pointer;
        background-color: transparent;
        border: none;
        width: 100%;
        text-align: left;
        padding: 10px;
    }

    button:hover {
        background-color: rgb(245, 245, 245);
    }
}

.validation {
    z-index: -1;
    opacity: 0;
    position: absolute;
}
