@import "../../../../styles/form.scss";
@import "../../../../../config.scss";

.certTextArea {
    display: block;
    width: 100%;
    height: 200px;
    @include code-text-inactive;
}

.totpSwitch {
    margin-top: 6px;
}

.error {
    margin: 24px;
}
