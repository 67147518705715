@import "../../../../../config.scss";

.children {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 600px;
    color: $text-darkest;
}

.footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.grantRevokeLicense {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.header {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.licenseStatus {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.licenseState {
    font-weight: 700;
}

.licenseStateLabel {
    margin-right: 8px;
}
