@import "../../../config.scss";
@import "../../../styles/form.scss";
@import "../../../styles/grid.scss";

.preferredAppText {
    margin-top: 15px;
    height: 40px;
    padding: 0 $spacing-3x;
}

.cappedGrid {
    height: calc(100% - 60px);
}

.exclusionLink {
    margin-left: $spacing-half;
}

.selectLabel {
    display: block;
    margin-bottom: $spacing-half;
}

.cancelButton {
    height: 36px;
    padding: 10px 15px !important;
}

.dialogActions {
    padding: 10px 15px;
    margin-top: 20px !important;
    border-top: 1px solid $border-lighter;
    background-color: $bg-lighter;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
}
