@import "../../../config.scss";
@import "../../../styles/overview.scss";

.formLabel {
    text-transform: uppercase;
    color: $text-dark;
    margin: 25px 0 5px;
}

.installationParamsGridContainer {
    height: 40%;
    margin-top: 20px;
}

.certParamsContainer {
    max-width: 70%;
    margin-top: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
