@import "../../../styles.scss";

.switch {
    display: inline-block;
    width: 58px;
    height: 24px;
    border-radius: 30px;
    outline: none;
    border: none;
    padding: 0 3px;
    background-color: $text-light;
    cursor: pointer;

    &.disabled {
        background-color: $border-dark;
        cursor: not-allowed;
    }

    &.isSwitchOn {
        background-color: $brand;

        .knob {
            left: 34px;
        }
    }

    &.disabled.isSwitchOn {
        background-color: $border-dark;
        cursor: not-allowed;
    }
}

.knob {
    display: block;
    width: 18px;
    height: 18px;
    background-color: $bg-lightest;
    border-radius: 50%;
    position: relative;
    top: 3px;
    left: 0;
    transition: left 0.1s ease-in;
}
