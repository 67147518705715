@import "../../../../config.scss";

.certTextArea {
    display: block;
    margin-top: 8px;
    width: 90%;
    height: 200px;
    @include code-text-inactive;
}

.copyButton {
    margin-left: 4px;
}
