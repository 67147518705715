@import "../../config.scss";

.button {
    padding: 8px 30px;
    gap: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    min-height: 33px;
    line-height: normal;
    white-space: nowrap;

    // Double selector to increase specificity
    // Otherwise the :hover and :active states will apply
    &:disabled:disabled {
        background-color: $gray-300;
        color: $gray-700;
        border: 1px solid $gray-300;
        cursor: not-allowed;
    }
}

.withIcon {
    padding: 8px 30px 8px 24px;
}

.noChildren {
    padding: 8px 24px;
}

.primary {
    background-color: $bluegray-1000;
    color: $text-white;
    border: 1px solid $bluegray-1000;

    &:hover {
        background-color: $bluegray-1100;
        color: $text-white;
    }

    &:active {
        background-color: $bluegray-1200;
        color: $text-white;
    }
}

.secondary {
    background-color: $bluegray-100;
    color: $text-darkest;
    border: 1px solid $bluegray-900;

    &:hover {
        background-color: $bluegray-200;
        color: $text-darkest;
    }

    &:active {
        background-color: $bluegray-400;
        color: $text-darkest;
    }
}
.secondarySolid {
    background-color: $bluegray-500;
    color: $text-darkest;
    border: none;
    font-weight: 500;

    &:hover {
        background-color: $bluegray-550;
        color: $text-darkest;
    }

    &:active {
        background-color: $bluegray-600;
        color: $text-darkest;
    }
}

.destructive {
    background-color: $bluegray-100;
    color: $error-button-default;
    border: 1px solid $error-button-default;

    &:hover {
        background-color: $bluegray-200;
        color: $error-button-hover;
    }

    &:active {
        background-color: $bluegray-400;
        color: $error-button-active;
    }
}

.destructiveSolid {
    background-color: $error-button-default;
    color: $text-white;
    border: 1px solid $error-button-default;

    &:hover {
        background-color: $error-button-hover;
        color: $text-white;
    }

    &:active {
        background-color: $error-button-active;
        color: $text-white;
    }
}

.loading {
    cursor: wait;
    pointer-events: none;
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: $gray-300;
    color: $gray-700;
    border: 1px solid $gray-300;
}

.small {
    padding: 3px 16px;
    min-height: unset;
}
