@import "../../config.scss";

.premiumFeatureTag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: $spacing-1x;
    font-size: 8px;
    height: 16px;
    border-radius: $spacing-2x;
    border: 1px solid $cyan-400;
    background-color: $cyan-50;
    color: $cyan-400;
    padding: $spacing-half;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 2px;
    width: 120px;
}
