.labelClassName {
    font-size: large;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.removeButton {
    height: 35px;
}
