@import "../../../../config.scss";
@import "../../../../styles/form.scss";

.container {
    padding: $spacing-2x;
    max-width: 800px;
}

.title {
    text-transform: uppercase;
    font-size: $font-size-small;
}

.form {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
}

.footer {
    margin-top: $spacing-4x;
}
