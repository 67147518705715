@import "../../../styles.scss";

.container {
    display: flex;

    &:focus-within {
        outline: rgb(0 95 204) auto 1px;
        border-color: transparent;
    }

    .input {
        flex: 1;
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        outline: none;
    }

    .button {
        width: 40px;
        @include border-dark;

        border-radius: 4px;
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background-color: $bg-lightest;
        cursor: pointer;
    }

    .icon {
        display: flex;
        justify-content: center;
        width: 28px;
        height: 12px;
    }

    .input:disabled {
        background-color: $bg-light;
    }

    .input:disabled + .button {
        background-color: $bg-light;
    }
}
