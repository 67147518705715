@use "sass:math";

// Brand

$brand-dark: #2ca3b8;
$brand: #23b9d8;
$brand-lightest: #f2fdff;

// Background

$bg-darkest: #2f3243;
$bg-light: #f6f7fb;
$bg-lightest: #fff;

// Border
// border-colors
$border-darker: #686c82;
$border-dark: #bbbecf;

// border-radius
$border-radius: 4px;
$border-radius-large: 8px;

// Text

$text-darkest: #2f3243;
$text-dark: #949494;
$text-light: #a2a5b7;
$text-lighter: #eaebf2;
$text-lightest: #fff;

// Error

$error-dark: #aa1300;
$error: #e52f18;
$error-lightest: #f2dede;

// Warning

$warning-dark: #616018;
$warning: #fdce3a;
$warning-light: #fdfaec;

// Success

$success: #00c884;
$success-light: #e9f8f2;

// Orange
$orange: #f07402;

// Icon size

$icon-size-extra-small: 10px;
$icon-size-small: 12px;
$icon-size-normal: 16px;
$icon-size-large: 22px;
$icon-size-extra-large: 24px;

// Private Font-size

$-font-size-small: 12px;
$-font-size-normal: 14px;
$-font-size-large: 16px;
$-font-size-largest: 28px;

// Private Font-weight

$-font-weight-normal: 400;
$-font-weight-bold: 600;

@mixin regular-text {
    font-size: $-font-size-normal;
    color: $text-darkest;
}

@mixin placeholder-text {
    font-size: $-font-size-normal;
    color: $text-dark;
}

@mixin disabled-text {
    background-color: $bg-light;
    color: $text-dark;
}

@mixin italic-text {
    font-style: italic;
    color: $text-dark;
}

@mixin bold-text {
    font-weight: $-font-weight-bold;
    color: $text-darkest;
}

@mixin link-text {
    font-style: italic;
    font-size: $-font-size-normal;
    text-decoration-line: underline;
    color: $brand;
}

@mixin small-text {
    font-weight: $-font-weight-normal;
    font-size: $-font-size-small;
    color: $text-darkest;
}

@mixin small-placeholder-text {
    font-weight: $-font-weight-normal;
    font-size: $-font-size-small;
    color: $text-dark;
}

@mixin small-bold-text {
    font-weight: $-font-weight-bold;
    font-size: $-font-size-small;
    color: $text-darkest;
}

@mixin small-uppercase-text {
    font-weight: $-font-weight-normal;
    font-size: $-font-size-small;
    text-transform: uppercase;
    color: $text-light;
}

@mixin page-header-text {
    font-size: $-font-size-largest;
    color: $text-darkest;
}

@mixin page-title-text {
    font-weight: 500;
    font-size: $-font-size-largest;
    color: $text-darkest;
}

@mixin label-header-text {
    font-size: $-font-size-large;
    color: $text-darkest;
}

@mixin label-bold-header-text {
    font-weight: $-font-weight-bold;
    font-size: $-font-size-large;
    color: $text-darkest;
}

@mixin border-dark {
    border: 1px solid $border-dark;
    border-radius: $border-radius;
}

@mixin border-dark-large {
    border: 1px solid $border-dark;
    border-radius: $border-radius-large;
}
