@import "../../../pre-v3/config.scss";
@import "../../../pre-v3/styles/overview.scss";
@import "../../../pre-v3/styles/form.scss";
@import "../../../pre-v3/styles/input-list.scss";

.formFields {
    max-width: 800px;
}

.table {
    margin-top: $spacing-6x;
    margin-left: $spacing-6x;
}

.attribute {
    color: $text-semidark;
    margin-bottom: $spacing-2x;
}

.enforcementLabel {
    margin-top: $spacing-2x;
    color: $text-semidark;
}

.enforcementMargin {
    margin-left: 25px;
}

.hostInput {
    width: 80%;
    margin-left: 25px;
}

.sectionContainer {
    margin-top: $spacing-2x;
    width: 80%;
}

.download {
    margin-top: $spacing-2x;
    display: block;
}

.marginLeft {
    margin-left: 25px;
}

.formLabel {
    margin-bottom: 32px !important;
    flex: 1;
}

.formInput {
    width: 100%;
}

.errorBanner {
    padding: $spacing-1x $spacing-3x $spacing-1x $spacing-3x;
}

.resource {
    border: 1px solid $border-dark;
    border-radius: $border-radius;
    background-color: $bg-lighter;
    padding: 18px 16px;
    margin-bottom: $spacing-2x;
    width: 90%;

    td {
        padding: 4px;
        font-weight: 500;
    }
}

.statusIcon {
    font-size: 20px;
    margin-left: 2px;
    margin-right: $spacing-1x;
    position: relative;
    top: -1px;
}

.inactive {
    background-color: $bg-lighter;
    border-color: $text-semidark;

    .statusIcon {
        color: $text-dark;
    }
}

.enforcing {
    background-color: $success-lightest;
    border-color: $success-dark;

    .statusIcon {
        color: $success-dark;
    }
}

.permissive {
    background-color: $warning-lightest;
    border-color: $warning-dark;

    .statusIcon {
        color: $warning-dark;
    }
}

.statusHeader {
    color: $text-semidark;
    font-weight: 500;
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

.statusText {
    font-weight: 600;
    font-size: $font-size-large;
}

.primary {
    background-color: $highlight-primary;
    color: $text-lightest;
    border: 0;
    padding: 10px 15px;

    &:hover {
        background-color: $highlight-primary-dark;
    }

    &[disabled] {
        background-color: $highlight-primary-lighter;
    }
}

.textPrimary {
    border: 1px solid $border-light;
    background-color: transparent;
    color: $highlight-primary-light;

    &:hover {
        color: $highlight-primary;
    }
}

.container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0;
}
