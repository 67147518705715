.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.background {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 24px;
    padding: 48px 24px;
    max-width: 300px;
}

.banner {
    width: 100%;
}

.logo {
    width: 50%;
}
