@import "../config.scss";

.gridWithToolbar {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 $spacing-3x;
    display: flex;
    flex-direction: column;
}

.gridFullWithToolbar {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.gridBanner {
    margin-top: $spacing-1x;
}

.toolbarButton {
    margin-left: $spacing-1x;
}

.filterButton {
    height: 35px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gridContainer {
    width: 100%;
    margin-top: 20px;
    flex: 1;
}
