@import "../../../config.scss";

.table {
    width: 100%;

    th {
        background-color: $gray-100;
        font-weight: 700;
        font-size: 12px;
    }

    &,
    & th,
    & td {
        border: 1px solid $gray-500;
        border-collapse: collapse;
        color: $text-darkest;
    }

    & th,
    & td {
        padding: 8px;
    }
}
