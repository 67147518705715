@import "../../../../pre-v3/config.scss";

.statusIcon {
    width: 15px;
    height: 15px;
}

.statusList {
    td {
        overflow: unset;
        text-overflow: unset;
    }
}

.darkerRed {
    color: $red-900;
}
