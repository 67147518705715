@import "../../../pre-v3/config.scss";

input.input {
    background: $bg-lighter;
    border-color: transparent;
    border-radius: 4px;
    padding: 12px;
}

// TODO: Remove these when equivalent overrides are removed in src/index.scss
input[type].input:focus {
    outline: rgb(0 95 204) auto 1px;
    border-color: transparent;
}
