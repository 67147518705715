@import "../../../../styles.scss";

.trustFactorCell {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    column-gap: 8px;
    row-gap: 24px;
}

.sourceCell {
    height: 100%;
    padding: 8px 0;
}

.trustFactorTitle {
    display: flex;
    column-gap: 4px;
    align-items: center;
}

.extendedFactors {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    grid-column-start: 2;

    .unsatisfiedPill {
        color: $error;
        background-color: $bg-lightest;
        border-color: $error;
        border-width: 2px;
    }
}

.trustFactorIsSatisfied {
    color: $success;
}

.trustFactorIsNotSatisfied {
    color: $error;
}
