@import "../../config.scss";

.container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.trigger {
    visibility: hidden;
    width: 0px;
    height: 0;
    border: none;
}

.inputContainer {
    position: relative;
    span {
        white-space: pre;
    }
    width: 100%;
}
.input {
    color: transparent;
    caret-color: black;
}
.input:disabled {
    color: transparent;
}

.text {
    @include text-field-active-text;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 8px 12px;

    pointer-events: none;
    display: flex;
    align-items: center;
    overflow: scroll;
    text-wrap: nowrap;

    &::-webkit-scrollbar {
        display: none;
    }
}

.disablesText {
    @include text-field-disabled-text;
}

.variable {
    color: $variable-color;
}

.content {
    @include border-inactive;
    @include shadow;
    width: 100%;
    background-color: $background-white;
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
}

.item {
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $bluegray-1300;

    cursor: pointer;

    &:focus {
        background-color: $bluegray-500;
        outline: none;
    }
}

// TODO - readd when warning is defined
// .banner {
//     margin-top: 8px;
//     width: 100%;
//     span {
//         text-wrap: wrap;
//     }
// }
