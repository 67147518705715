.container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.error {
    margin-bottom: 16px;
}

.search {
    width: 250px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}

.overviewTopContainer {
    display: flex;
    gap: 24px;
    align-items: center;
}
