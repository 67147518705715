@import "../../../../../config.scss";
@import "../../../../../styles/overview.scss";
@import "../../../../../styles/form.scss";
@import "../../../../../styles/input-list.scss";

.bulkConnectSelect {
    // padding-left: 5px;
    width: calc(75%);
}

.overviewRightGridContainer {
    padding-top: $spacing-3x;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-bottom: 0;
}

.section {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
