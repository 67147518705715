@import "../../../pre-v3/config.scss";

.container {
    background-color: $white;
    padding: $spacing-2x;
    padding-left: $spacing-3x;
    border: 1px solid $border-light;
    box-shadow: 0 0 4px 1px rgb(3 24 40 / 15%);
    border-radius: $border-radius-large;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.noData {
    justify-content: center;
}
