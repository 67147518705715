@import "../../config.scss";

.container {
    position: relative;
    isolation: isolate;
    display: flex;
    justify-content: space-between;
}

.topNavigationBar {
    position: relative;
    padding: 12px 0 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 24px;

    width: 100%;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    li {
        list-style-type: none;
    }
}

.topNavigationBackgroundOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    background-color: #9ebbdd;
    opacity: 0.7;
    mix-blend-mode: soft-light;
}

.topNavigationBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    opacity: 0.3;
    background: url("../../images/ethereal-banner.png");
    background-size: cover;
    background-position: 0 -138px;
    background-repeat: no-repeat;
}

.headingTitle {
    color: $text-darkest;
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
}

@mixin activeItem {
    color: $text-white;
    background-color: $background-darkest;
    border-radius: 30px;
}

.topNavMenuItem {
    display: block;
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 12px;

    &[aria-current="page"] {
        @include activeItem;
    }

    &:hover {
        @include activeItem;
    }
}

.rightContainer {
    position: relative;
    z-index: 2;
    padding: 12px;
}

.profileLinks {
    display: flex;
    gap: 36px;
}

.profileLink {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    color: $text-darkest;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    white-space: nowrap;
    cursor: pointer;
    align-self: flex-start;
}

.orgLink {
    border-radius: 30px;
    background: #fff;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    h1 {
        padding-top: 12px;
        align-self: flex-end;
    }
}

.bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
