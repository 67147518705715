@import "../../../pre-v3/config.scss";

.markdown ol {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
}

.listItem {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacing-half;
}

.badge {
    margin-right: $spacing-1x;
}
