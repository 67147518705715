.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    flex: 1;
}

.search {
    width: 250px;
}
