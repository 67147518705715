.protectionProfileForm {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 24px;

    .tabContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        row-gap: 24px;
    }

    .deviceSearch {
        width: 200px;
    }

    // ContentFiltering

    .contentFilteringAccordionSummary {
        border: 0;
        border-radius: 0;
    }

    .contentFilteringAccordionSummaryHeader {
        padding: 0;
    }

    .categoryDropdown {
        margin-left: auto;
    }

    // Assignments

    .generalInformation {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
    }

    .fullWidthTextarea {
        width: 100%;
        resize: vertical;
        min-height: 80px;
    }

    .attachRoles {
        margin-left: auto;
    }

    .assignmentTabCategoryRow {
        padding: 20px 16px 16px 16px;
    }

    .assignmentTabCategoryRowHeader {
        padding: 0;
    }
}

// overview

.statusContainerClassName {
    tr > td:first-child {
        padding-left: 0;
    }
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    justify-content: space-between;
    overflow-x: hidden;
}

.errorBanners {
    padding: 24px 0;
}

.errorBanner {
    margin: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}

.grid {
    flex: 1;
}
