@import "../../../styles.scss";

.screenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 96px;
}

.screenText {
    max-width: 500px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 32px;
}

.heading {
    @include page-title-text;
    line-height: 32px;
    margin: 0;
    margin-bottom: 16px;
}

.contactUs {
    display: flex;
    width: 100%;
    gap: 48px;
    align-items: center;
    padding: 32px 32px 0 32px;
    overflow: hidden;

    .contactUsImage {
        width: 50%;
        overflow: hidden;
    }

    .contactUsSection {
        width: 50%;
        gap: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        min-width: 400px;
    }

    .contactUsTitle {
        p {
            margin: 0;
            @include page-title-text;
            font-weight: 700;
            text-align: center;
            white-space: break-spaces;
        }
    }

    .contactUsButtonGroup {
        display: flex;
        gap: 32px;
    }

    .contactUsButton {
        white-space: nowrap;
    }
}

.contactUsSteps {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .contactUsStep {
        display: flex;
        gap: 32px;
        align-items: center;
    }

    .contactUsStepLabel {
        font-weight: 400;
        font-size: 16px;
        flex: 1;
    }

    .contactUsStepIcon {
        color: $text-lightest;
        font-size: 32px;
    }

    .contactUsStepIconWrapper {
        background: $brand;
        border-radius: 50%;
        display: flex;
        width: 58px;
        height: 58px;
        align-items: center;
        justify-content: center;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 32px;
}

.subText {
    font-size: 16px;
}
