@import "../../config.scss";

.jsonTextArea {
    border: 1px solid $border-light;
}

.jsonActions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $spacing-1x;
}

.copyButton {
    margin-right: $spacing-1x;
    margin-bottom: $spacing-1x;
}
