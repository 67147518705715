@import "../../../config.scss";

.title {
    font-size: $font-size;
    font-weight: 600;
    margin-top: $spacing-1x;
    margin-bottom: $spacing-1x;
}

.select {
    font-size: $font-size-small;
    margin: $spacing-half 0;

    > div {
        padding: 10px !important;
    }
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 75%;
    margin-top: 15px;
}

.toggleButton {
    letter-spacing: $letter-spacing;
    border: 1px solid $black;

    button {
        color: $text-light;
        font-weight: 500;
        padding: 8px 18px;
        border-color: $black;

        &:hover {
            color: $black;
            background-color: $white;
        }
    }

    button[class*="toggleButtonActive"] {
        background-color: $black !important;
        color: $white;
    }
}

.toggleButtonLabel {
    height: 15px;
}
