@import "../../config.scss";

.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.25);

    .children {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .title {
        font-family: $font-family-serif;
        font-size: 18px;
        font-weight: 700;
    }

    .actionButton {
        font-size: 14px;
        font-weight: 500;

        cursor: pointer;

        &:hover {
            background-color: inherit;
            color: inherit;
        }
    }

    &.large {
        min-height: 160px;
        padding: 24px;

        .group {
            gap: 24px;
        }
    }
}

.group {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.warning {
    background-color: $feedback-warning-light;
    color: $feedback-warning-dark;

    .actionButton {
        background-color: $feedback-warning-dark;
        border: solid 1px $feedback-warning-dark;
    }
}

.information {
    background-color: $feedback-info-light;
    color: $feedback-info-dark;

    .actionButton {
        background-color: $feedback-info-dark;
        border: solid 1px $feedback-info-dark;
    }
}

.genericPrimary {
    background-color: $feedback-generic-1-light;
    color: $feedback-generic-1-dark;

    .actionButton {
        background-color: $feedback-generic-1-dark;
        border: solid 1px $feedback-generic-1-dark;
    }
}

.genericSecondary {
    background-color: $feedback-generic-2-light;
    color: $feedback-generic-2-dark;

    .actionButton {
        background-color: $feedback-generic-2-dark;
        border: solid 1px $feedback-generic-2-dark;
    }
}

.error {
    background-color: $feedback-error-light;
    color: $feedback-error-dark;

    .actionButton {
        background-color: $feedback-error-dark;
        border: solid 1px $feedback-error-dark;
    }
}

.success {
    background-color: $feedback-success-light;
    color: $feedback-success-dark;

    .actionButton {
        background-color: $feedback-success-dark;
        border: solid 1px $feedback-success-dark;
    }
}
