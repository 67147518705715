.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}

.buttons {
    margin-top: 24px;
}
