@import "../../../pre-v3/config.scss";

.background {
    /* fallback for old browsers */
    background: $white;
    background: linear-gradient(to left, #fff, #eee);
    background: url("../../../images/login_bg.jpeg") no-repeat;
    background-size: cover;
    height: 100%;
}
