@import "../../../../../../../pre-v3/config.scss";

.icon {
    margin-right: $spacing-half;
}

.notEvaluatedColor {
    color: $bluegray-300;
}

.alwaysDenyColor {
    color: $red-900;
}

.lowTrustLevelColor {
    color: $red-500;
}

.mediumTrustLevelColor {
    color: $yellow-500;
}

.noEffectColor {
    color: $green-500;
}
