@import "../../config.scss";

.rounded {
    border-radius: 18px;
}

.menuButtonChildren {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    list-style: none;

    li {
        margin-block-start: 0;
        margin-block-end: 0;
        padding-block-start: 0;
        padding-block-end: 0;
        list-style: none;
    }
}

.iconSpacing {
    margin-left: $spacing-1x;
}

.chevronDown {
    margin-left: $spacing-1x;
}
