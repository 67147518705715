@import "../../../config.scss";
@import "../../../styles/overview.scss";

.info {
    padding: $spacing-2x;
}

.leftContainer {
    width: 32%;
    min-width: 325px;
    padding-left: $spacing-1x;
}

.rightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

@media (max-width: 1430px) {
    .leftContainer {
        width: 100%;
    }

    .rightContainer {
        flex: unset;
        height: 600px;
        width: 100%;
    }
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    padding-bottom: 8px;
}
