@import "../../config.scss";

.input {
    @include border-inactive;
    @include text-field-active-text;
    background-color: transparent;

    padding: 8px 12px;
    width: 100%;

    &:focus {
        outline: none;
        @include border-active;
    }

    &::placeholder {
        @include text-field-placeholder-text;
    }

    &:disabled {
        @include text-field-disabled-text;
        @include border-disabled;
        background-color: $gray-300;
    }
}
