.container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
}

.label {
    font-weight: 600;
    font-size: 16px;
}

.severityContainer {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.severitySelect {
    width: 128px;
}
