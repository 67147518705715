@import "../../../../pre-v3/config.scss";

.container {
    width: 850px;
}

.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    &:not(:first-of-type) {
        margin-top: $spacing-5x;
    }
}

.grid {
    &:global(.ag-theme-material) {
        :global(.ag-center-cols-clipper) {
            min-height: unset;
        }
    }
}

.viewMoreButton {
    // Matches AG Grid's rows
    padding: 10px 23px;
}
