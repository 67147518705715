@import "../../../../styles.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $border-dark;
    background-color: $bg-light;
}

.topBar {
    width: 100%;
    height: 62.5px;
    padding: 15px;
    background-color: $bg-darkest;
}

.logoImage {
    height: 100%;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url("../../../../images/cse-horiz-white.svg");
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
    width: 375px;
    height: 100%;
}

.card {
    background-color: $bg-lightest;
    padding: 10px 15px;
    box-shadow: 0 1.25px 2.5px rgb(0 0 0 / 25%);
    border-radius: 6.25px;
}

.title {
    @include label-bold-header-text;

    font-size: 10px;
    text-align: center;
    position: relative;

    svg {
        position: absolute;
        top: 25%;
        left: 10px;
        width: 17.5px !important;
        height: 17.5px;
        color: $error;
    }
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    @include label-header-text;

    font-size: 10px;
    text-align: center;

    .button {
        color: $text-lightest;
        background-color: $border-darker;
        border-radius: 2.5px;
        padding: 5px 15px;
    }
}

.table {
    th {
        @include small-uppercase-text;

        color: $text-dark;
        font-size: 7.5px;
    }

    td {
        @include small-text;

        color: $text-dark;
        font-size: 7.5px;
    }
}

.poweredBy {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8.75px;

    @include small-text;

    font-size: 7.5px;
}
