@import "../../../styles.scss";

.container {
    @include border-dark-large;

    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    margin: 24px;
    padding: 32px 32px 16px;

    h3,
    h4 {
        margin: 0;
    }
}

.starsIcon {
    position: absolute;
    top: 5px;
    left: 12px;
}

.smartSearchResults {
    @include small-placeholder-text;
}

.sources {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.cannedResponsesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 16px;
}

.cannedResponseButton {
    @include border-dark;

    width: 496px;
    padding: 20px 21px;

    text-align: start;
    background: $bg-lightest;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.wasThisResponseHelpful {
    @include small-placeholder-text;

    display: flex;
    column-gap: 12px;
}
