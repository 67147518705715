@import "../../config.scss";

.icon {
    width: 14px !important;
}

.success {
    color: $success-dark;
}

.button {
    border: none;
}

.copyButton {
    padding: 0;
    background-color: transparent;
}
