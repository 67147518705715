.formRow {
    align-items: center;
}

.formLabel {
    text-wrap: balance;
}

.checkboxes {
    display: flex;
    column-gap: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 !important;
}

.hiddenInput {
    position: relative;
    opacity: 0;
    padding: 0;
}
