@import "../../../../pre-v3/styles/grid.scss";

.errorBanner {
    margin-bottom: 16px;
}

.container {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
