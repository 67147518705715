.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;
}

.headerSection {
    display: flex;
    justify-content: space-between;
}

.header {
    display: flex;
    justify-content: space-between;
}
