@import "../../../../../pre-v3/config.scss";

.form {
    margin-top: $spacing-2x;
}

.flex {
    display: flex;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}
