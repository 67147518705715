@import "../../config.scss";

.dropdown {
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    margin: 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: $spacing-2x;
    }
}

.filterTitle {
    font-weight: 600;
}

.filter {
    padding-left: $spacing-4x !important;
    cursor: pointer;
    border-top: 1px solid $border-dark;

    &:hover {
        background-color: $highlight-primary;
        color: white;
    }
}

.chevronRight {
    margin-left: $spacing-2x;
}

.allFilters {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: $spacing-2x;
    font-weight: 600;
    border-bottom: 1px solid $border-dark;
}

.chevronLeft {
    margin-right: $spacing-2x;
}

.filterContainer {
    padding: $spacing-4x $spacing-3x;
    max-height: 500px;
    max-width: 672px;
}
