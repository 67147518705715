@import "../../../styles.scss";

.leftNav {
    width: 220px;
    min-width: 220px;
    height: 100%;
    background-color: $bg-light;
    position: relative;
    padding-top: 16px;
}

.leftNavTitle {
    margin: 24px 0 0 24px;
    @include small-uppercase-text;
}

.leftNavArrow {
    position: absolute;
    bottom: 0;
    right: 5px;
}

.leftNavCollapsed.leftNav {
    padding-top: 20px;
    width: 50px;
    min-width: 50px;
}
