@import "../../config.scss";

.container {
    max-width: 1280px;
    margin: 0 auto $spacing-4x;
    padding: $spacing-4x $spacing-4x 0;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-3x;
    justify-content: space-between;
    align-items: center;
}

.picker {
    border: 1px solid $border-dark;
    border-radius: $border-radius;
    overflow: hidden;
}

.dashboardGrid {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 110px;
    column-gap: 16px;
    row-gap: 16px;
    grid-template-areas:
        "device-count      user-count        service-count     policy-count      role-count        at-count"
        "os                os                trust-level       trust-level       top-10            top-10"
        "os                os                trust-level       trust-level       top-10            top-10"
        "enforcement       enforcement       at-status         at-status         top-10            top-10"
        "enforcement       enforcement       at-status         at-status         top-10            top-10"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "service-access    service-access    service-access    service-access    service-access    service-access"
        "device-access     device-access     device-access     device-access     device-access     device-access"
        "device-access     device-access     device-access     device-access     device-access     device-access"
        "device-access     device-access     device-access     device-access     device-access     device-access"
        "device-access     device-access     device-access     device-access     device-access     device-access"
        "device-access     device-access     device-access     device-access     device-access     device-access";
}
