@import "../../../../config.scss";

.table {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    width: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}

.actionButton {
    border: 0;
    padding: 4px;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: $bluegray-300;
        border-radius: 8px;
    }
}

.domainsTable {
    :global(.ag-root-wrapper),
    :global(.ag-header),
    :global(.ag-row) {
        background-color: transparent;
    }
}
