.mainContainer {
    position: relative;
}
.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
