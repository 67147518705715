@import "../../../config.scss";

.cancelBtn {
    margin-right: 0;
}

.buttons {
    display: flex;
    gap: 16px;
}
