@import "../../../pre-v3/config.scss";

.container {
    display: flex;

    &:focus-within {
        outline: rgb(0 95 204) auto 1px;
        border-color: transparent;

        .input[type] {
            outline: none;
        }
    }

    .input {
        flex: 1;
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .button {
        width: 40px;
        border: transparent;
        border-radius: 4px;
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        background: $bg-lighter;
        cursor: pointer;
    }

    .input:disabled + .button {
        background-color: $bg-light;
        color: $border-darker;
    }
}
