.orgName {
    display: flex;
    align-items: center;
    gap: 10px;
}

.overviewContainer {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 8px 24px 8px 16px;
    overflow: hidden;
}

.overviewLeftContainer {
    width: 32%;
    min-width: 325px;
    padding-left: 8px;
    padding-right: 16px;
}

.overviewRightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

@media (max-width: 1000px) {
    .overviewLeftContainer {
        width: 100%;
    }

    .overviewRightContainer {
        flex: unset;
        margin-top: 50px;
        height: 600px;
        width: 100%;
    }
}

.tabContainer {
    overflow: auto;
}

.statusContainer {
    width: 100%;
    margin-bottom: 16px;
}

.detailsContainer {
    width: 100%;
}
