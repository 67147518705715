@import "../../../styles.scss";

.osInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.osInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.osIcon {
    color: $text-dark;
}

.osInput {
    width: 100%;
}
