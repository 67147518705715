@import "../../config.scss";
@import "../../styles/page.scss";

.settingsContainer {
    p {
        line-height: 22px;
    }

    h2 {
        line-height: 32px;
    }
}

.mspManageAdminContainer {
    flex: 1;

    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}
