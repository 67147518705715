@import "../../../styles/form.scss";
@import "../../../config.scss";

.login {
    height: 100vh;

    .loginForm {
        width: 50%;
        min-width: 400px;
        padding: 8% 0 0;
        margin: auto;

        .form {
            background: $white;
            border-radius: $border-radius;
            max-width: 400px;
            margin: 0 auto 100px;
            padding: 45px;
            text-align: center;
            box-shadow:
                0 0 80px 0 rgb(0 0 0 / 8%),
                0 4px 4px 0 rgb(0 0 0 / 4%);

            input {
                background: $bg-lighter;
                width: 100%;
                border: 0;
                margin: 0 0 $spacing-2x;
                padding: $spacing-2x;

                .domain {
                    width: 60%;
                    border-radius: $border-radius 0 0 $border-radius;
                }
            }

            .submitButton {
                text-transform: uppercase;
                padding: 15px;

                &[disabled] {
                    pointer-events: none;
                }
            }

            .orgname {
                text-align: center;
                margin: 0 0 24px $spacing-1x;
            }

            .orgname strong {
                letter-spacing: 1px;
                font-weight: 500;
            }

            .orgnameinput {
                display: flex;
            }

            .inputurl {
                margin: 0 0 $spacing-2x;
                padding: $spacing-2x;
                line-height: 18px;
                width: 45%;
                border-radius: 0 4px 4px 0;
                background-color: #d9e3e5;
                font-size: 14px;
                letter-spacing: 0.3px;
                color: #444;
            }

            .message {
                margin: $spacing-1x 0 0;
                color: #aaa;
                font-size: $font-size-small;

                a {
                    color: #23b9d8;
                    font-weight: 500;
                    text-decoration: none;
                }
            }

            .register-form {
                display: none;
            }

            .logo {
                height: 50%;
                width: 80%;
                margin: 16px $spacing-1x 60px 0;
            }
        }
    }

    .link {
        cursor: pointer;
        color: #23b9d8;
        font-weight: 500;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }
}

.banner {
    margin: $spacing-2x 0;
}

.submitButton {
    text-transform: uppercase;
    padding: 15px;
    width: 100%;

    &[disabled] {
        pointer-events: none;
    }
}
