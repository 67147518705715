@import "../../../config.scss";

.form {
    margin: 8px 0;
}

.banner {
    p {
        margin-bottom: 0;
    }
}

.preReqCheckList {
    margin-bottom: 32px;
}

.subTitle {
    font-weight: 500;
    color: $bluegray-1300;
}

.list {
    margin: 0;

    li {
        align-items: center;
        list-style: none;
        padding: 8px;
    }
}

.checkList {
    display: flex;
    column-gap: 8px;
}

.preReqLabel {
    p {
        margin-bottom: 0;
    }
}

.bulletList {
    margin-left: 48px;
    margin-top: 4px;

    li {
        list-style: square;
    }
}

.table {
    border-collapse: collapse;
    margin-left: 40px;
    border: 1px solid $bluegray-400;
    margin-bottom: 16px;
    margin-top: 16px;
}

.tr {
    border-bottom: 1px solid $bluegray-400;
}

.text {
    text-align: center;
    color: $bluegray-800;
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
}

.nameColumn {
    text-align: center;
    padding: 12px 40px 12px 60px;
}

$orange-600-v3: #f07402;

.columnColor {
    color: $orange-600-v3;
}
