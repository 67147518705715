@import "../../../config.scss";

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4em;
    color: $bluegray-1300;
    margin-bottom: 8px;
}

.children {
    margin-left: 32px;
}
