@import "../../../styles.scss";

.container {
    display: flex;
    align-items: center;
    column-gap: 16px;

    appearance: auto;
    padding: 0;
    color: $text-darkest;
    border: none;
    background-color: $bg-lightest;
}

$swatchSize: 32px;

.colorSwatch {
    width: $swatchSize;
    height: $swatchSize;

    @include border-dark;
    background-color: $bg-lightest;
}
