.section {
    padding: 24px 24px 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}

.overviewContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 8px;
}

.overviewLeftContainer {
    min-width: 325px;
}

.overviewRightContainer {
    display: flex;
    flex-direction: column;
}
