.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

.body {
    flex: 1;
    overflow: auto;
}

.topNavRight {
    float: right;
    margin-top: 8px;
}
