@import "../../config.scss";
@import "../../styles.scss";

@mixin searchWidth {
    width: 425px;
}

$searchInputBackgroundColor: transparent;

@mixin searchInputStyles {
    color: $text-darkest;
    background-color: $searchInputBackgroundColor;
    border-bottom: 1px solid $text-darkest;

    &:hover {
        background-color: $searchInputBackgroundColor;
    }
}

.searchCard {
    background-color: $background-white;
    box-shadow: -6px 8px 7px 0px $card-box-shadow;
}

.searchInputContainer {
    @include searchWidth;

    input {
        border: none;
        border-radius: 0;
    }

    display: flex;

    &:focus-within {
        border-color: transparent;
    }

    .searchInput {
        @include searchInputStyles;

        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        outline: none;

        &::placeholder {
            color: $text-ai-search;
        }
    }

    .searchButton {
        @include searchInputStyles;

        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
        padding-right: 0;
        padding-left: 0;
        cursor: pointer;

        &:hover {
            color: $text-ai-search;
        }
    }
}

.resultPaneContainer {
    @include searchWidth;

    padding-top: 4px;
}

.loader {
    height: 150px;
}

.smartSearchResults {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px 16px;
}

.noSmartSearchResults {
    color: $text-darkest;
    font-size: 12px;
    font-weight: 400;
}

.smartSearchResultsText {
    font-size: 10px;
    font-weight: 600;
    color: $bluegray-800;
}

.moreOptionsButton {
    align-self: center;
    margin-top: 11px;
    margin-bottom: 11px;
}

.feedback {
    display: flex;
    column-gap: 12px;
    padding: 12px 10px;

    align-items: center;

    span {
        font-size: 10px;
        font-weight: 600;
        color: $bluegray-800;
    }
}

.link {
    padding: 8px;
}

.promptList {
    list-style-type: none;
}

.promptListItem {
    font-size: 12px;
    font-weight: 500;

    button {
        padding: 10px 16px;
        color: $bluegray-900;
        cursor: pointer;
    }
}

.promptListTitle {
    color: $bluegray-600;
    font-size: 10px;
    font-weight: 600;
    padding: 8px 16px;
}

.promptListItem {
    font-size: 12px;
    font-weight: 500;

    button {
        padding: 10px 16px;
        color: $bluegray-900;
        cursor: pointer;
    }
}

.promptListItem:hover {
    background-color: $bluegray-200;
}

.promptButton {
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;

    width: 100%;
    text-align: start;
}

.markdownResult {
    padding-left: 12px;

    p {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}
