@import "../../../styles.scss";

.container {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    max-width: 100%;
}

.label {
    @include small-uppercase-text;

    width: 130px;
    text-wrap: nowrap;
}

.value {
    @include regular-text;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin: 0;
    width: 600px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
