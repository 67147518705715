@import "fonts/fira-code/fira-code.css";
@import "fonts/libre-caslon-text/libre-caslon-text.css";
@import "fonts/red-hat-display/red-hat-display.css";

@import "config.scss";
@import "pre-v3/config.scss";
@import "pre-v3/index.scss";
@import "./styles.scss";
@import "pre-v3/styles/ag-grid-overrides.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    @include normal-body-text;
}

a {
    text-decoration: none;
}
