@import "../../config.scss";

.button {
    border: none;
    background: none;
    font: inherit;
    font-style: inherit;
    font-size: inherit;
    color: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
}

.button:not(:disabled) {
    cursor: pointer;
}

.button:disabled {
    color: $text-light !important;
}

.link {
    color: $highlight-primary;
    padding: 0;
}
