@import "../../../../../../pre-v3/config.scss";

.container {
    padding-top: 16px;
}

.errorBanner {
    margin-bottom: $spacing-2x;
}

.sectionTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #85899f;
}

.horizontalLine {
    width: 100%;
    border: 1px solid $border-light;
    margin-bottom: $spacing-4x;
}

.inputRow {
    display: flex;
    align-items: center;
    margin: $spacing-4x $spacing-10x;
    column-gap: $spacing-5x;

    label {
        font-weight: 400;
        font-size: $font-size;
        line-height: $font-size-larger;
        color: $text-semidark;
    }

    input {
        background: $bg-lightest;
        border: 1px solid $border-light;
    }
}

.saveButton {
    align-self: flex-start;
    width: 200px;
}
