@import "../../../../styles.scss";

.bottomPadding {
    padding-bottom: 48px;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    height: 100%;
    padding: 24px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.title {
    @include regular-text;
}

.testConnectionContainer {
    display: grid;
    grid-template-columns: 65px 180px 1fr;
    column-gap: 24px;
    row-gap: 16px;
}

.statusCell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-top: 4px;
}

.bold {
    @include bold-text;
}

.errors {
    padding: 0 24px 24px;
}

.overviewContainer {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}
