@import "../../../../pre-v3/config.scss";

.description {
    margin-bottom: 28px;
}

.tabs {
    margin-bottom: $spacing-4x;
}

.installActions {
    margin-top: $spacing-4x;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4x;
}

.banner {
    p {
        margin: 0;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}
