@import "../config.scss";

.form {
    max-width: 750px;
    min-width: 300px;
    width: 55%;
    margin: 20px auto;

    td {
        padding: $spacing-1x;
    }
}

.formCompact {
    td {
        padding: $spacing-half $spacing-1x;
    }
}

.formLabelLeft {
    display: block;
    margin-top: $spacing-2x;
    margin-bottom: $spacing-1x;
    width: 700px;
    font-weight: normal;
    text-align: left;
    color: $text-semidark;
}

.formLabel {
    text-align: left;
    min-width: 100px;
    text-transform: none;
    color: $text-semidark;

    label {
        font-weight: normal;
    }
}

.formTextAreaLabel {
    vertical-align: top;
    padding-top: 12px !important;
}

.formInput {
    width: 100%;
    border-radius: $border-radius;
}

.formTextArea {
    padding: 10px 20px;
    width: 100%;
    height: 100px;
    white-space: nowrap;
    resize: vertical;
    border-radius: $border-radius;
    border: 1px solid $border-dark;
}

.formSubmit {
    width: 60%;
    min-width: 200px;
}

.formFooter {
    font-style: italic;
    margin-top: 20px;
}

.hiddenSubmitButton {
    position: absolute;
    opacity: 0;
}

@media (max-width: 1400px) {
    .form {
        width: 70%;
    }
}

@media (max-width: 800px) {
    .form {
        width: 90%;
    }
}
