.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
    padding: 24px 24px 0;
}

.toolbarContainer {
    display: flex;
    align-items: center;
    column-gap: 24px;
}

.filterBar {
    flex: 1;
}

.grid {
    flex: 1;
}

.header {
    display: flex;
    justify-content: space-between;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
