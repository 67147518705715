@import "../../../../config.scss";

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: $spacing-3x;
}

.closeButton {
    height: $spacing-4x;
    width: $spacing-4x;
    border-radius: $spacing-2x;
    background-color: $bg-light;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    &:active {
        height: $spacing-4x;
        width: $spacing-4x;
        background-color: $bg-semidark;
    }
}

.formInput {
    width: 100%;
}

.addButton {
    margin-top: $spacing-2x;
}
