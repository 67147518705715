.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-bottom: 0;
}

.section {
    height: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
}
