.container {
    display: flex;
    align-items: flex-start;
    column-gap: 4px;
}

.cidrType {
    flex: 1 1 0;
    overflow: hidden;
}

.infoIcon {
    margin-top: 10px;
}

.multiInputWrapper {
    flex: 1 1;
    overflow: hidden;
}
