@import "../../../config.scss";

.modal {
    border: none;
    margin: auto;
    padding: 24px;
    overflow: hidden;

    &[open] {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    border-radius: 8px;
    background: #fff;
    box-shadow:
        0px 9px 46px 0px rgba(0, 0, 0, 0.12),
        0px 24px 38px 0px rgba(0, 0, 0, 0.14),
        0px 11px 15px 0px rgba(0, 0, 0, 0.15);

    &::backdrop {
        background-color: rgba(130, 130, 130, 0.5);
    }
}

.content {
    overflow-y: auto;
}

.header {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-items: center;

        svg {
            width: 10px;
        }
    }

    h2 {
        font-family: $font-family-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        flex: 1;
    }
}
