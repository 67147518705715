@import "../../../config.scss";
@import "../../../styles/overview.scss";

.containerWithHeading {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    row-gap: 20px;
}

.overviewContainer {
    padding: 0;
}

.policy {
    border: 1px solid $border-dark;
    border-radius: $border-radius;
    background-color: $bg-lighter;
    padding: 18px 16px;
    margin-bottom: $spacing-2x;
    width: 90%;

    td {
        padding: 4px;
        font-weight: 500;
    }
}

.policyEnforcing {
    background-color: $success-lightest;
    border-color: $success-dark;

    .statusIcon {
        color: $success-dark;
    }
}

.policyPermissive {
    background-color: $warning-lightest;
    border-color: $warning-dark;

    .statusIcon {
        color: $warning-dark;
    }
}

.noPolicy {
    background-color: $error-light;
    border-color: $error-dark;

    .statusIcon {
        color: $error-dark;
    }
}

.statusHeader {
    color: $text-semidark;
    font-weight: 500;
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

.statusIcon {
    font-size: 20px;
    margin-left: 2px;
    margin-right: $spacing-1x;
    position: relative;
    top: -1px;
}

.statusText {
    font-weight: 600;
    font-size: $font-size-large;
}

.policyTable {
    margin-top: $spacing-3x;
}

.serviceSpecContainer {
    padding-top: $spacing-2x;
    padding-bottom: $spacing-4x;
    padding-right: $spacing-2x;
}

.info {
    padding: $spacing-2x;
}

.hideCustomJson {
    display: none;
}

.content {
    margin-left: $spacing-1x;
    padding: $spacing-2x 0;
}

.banner {
    padding: $spacing-1x 0;
}

.formInput {
    width: 300px;
}

.right {
    float: right;
}

.container {
    width: 600px;
}

.submitButton {
    margin-left: 10px;
}

.footer {
    display: flex;
    flex-direction: row;
    margin-top: $spacing-2x;
    justify-content: flex-end;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
