@import "../../../config.scss";
@import "../../../styles/form.scss";

.form {
    width: 60%;
    max-width: 2000px;
}

.formLabel {
    width: 200px;
}

.screen {
    width: 100%;
    margin: 0;
}

.formRow {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
