@use "sass:color";
@import "../../../pre-v3/config.scss";

.banner {
    display: flex;
    height: 50px;
    padding: 0 $spacing-2x;
    box-shadow: 0 0 $spacing-1x color.change($black, $alpha: 0.25);
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: $spacing-2x;
    flex: 1;

    p {
        margin-bottom: 0;
    }
}

.iconButton {
    align-self: center;
}
