@import "../../../../../../../pre-v3/config.scss";
@import "../../../../../../../pre-v3/styles/grid.scss";

.description {
    font-weight: 500;
    font-size: $font-size-large;

    p {
        margin-bottom: $spacing-1x;
    }
}

.helpText {
    font-style: italic;
    color: $text-semidark;

    p {
        margin-bottom: $spacing-4x;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    column-gap: 8px;
}

.headerLeftContainer {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-1x;
}

.lastUpdatedTitle {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $font-size-smaller;
    line-height: $font-size-large;
    color: $text-semidark;
}

.lastUpdatedValue {
    font-weight: 800;
    line-height: 32px;
    color: $bluegray-600;
}

.buttonGroup {
    display: flex;
    gap: $spacing-1x;
}

.gridWithToolbar {
    padding-bottom: $spacing-3x;
    row-gap: $spacing-5x;
}

.grid {
    height: 100%;
}

.errorContainer {
    margin-top: $spacing-3x;
}

.createModalItems {
    display: flex;
    flex-direction: column;
    gap: $spacing-2x;
}

.createModalItem {
    display: flex;
    border: 2px solid $border-light-v3;
    border-radius: $border-radius;
    padding: $spacing-2x $spacing-3x;
    gap: $spacing-3x;
    width: 850px;
}

.createModalItemLeft {
    font-weight: 900;
    font-size: 48px;
    color: $brand-v3;
}

.createModalItemRight {
    margin: auto 0 auto auto;
}

.createModalItemContent {
    display: flex;
    flex-direction: column;
}

.createModalItemContentHeading {
    font-weight: 600;
    color: $text-v3;

    p {
        margin: 0;
    }
}

.createModalItemContentDesc {
    color: $text-semidark;

    p {
        margin: 0;
    }
}

.selectProfileWrapper {
    display: flex;
    align-items: center;
    gap: $spacing-2x;
    margin-top: $spacing-2x;
}

.selectProfileLabel {
    font-style: normal;
    font-weight: 600;
    color: $text-v3;

    p {
        margin: 0;
    }
}

.selectProfile {
    width: 310px;
}

.overlapCard {
    // Note: em values are hardcoded, to control the size of the icon based on font-size.
    position: relative;
    width: 1.125em;
    height: 1.08em;

    svg {
        font-size: 0.685em;
    }
}

.overlapCardTwoWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $bg-lightest;
    overflow: hidden;
    display: flex;
    border-radius: $border-radius;
    border: 1px solid $bg-lightest;
    border-right: 0;
    border-bottom: 0;
}

.overlapCardTwo {
    // Note: This is added to remove the extra white space on font-awesome card icon.
    margin-top: -2px;
}
