@import "../../../../pre-v3/config.scss";

.container {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
