@import "../../../../styles.scss";
@import "../../../../pre-v3/styles/grid.scss";

.sectionContainer {
    padding: 24px;
}

.search {
    width: 250px;
}

.container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.iconButton {
    margin-left: auto;
}

.errorBanner {
    margin-top: 16px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
