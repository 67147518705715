.banner {
    margin-bottom: 8px;
}

.input {
    width: 64px;
    margin-right: 8px !important;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
