@use "sass:color";
@import "../../../styles.scss";

.banner {
    border: 1px solid;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.bannerContent {
    flex: 1;
}

.banner:global(.info) {
    border-color: $brand;
    background-color: $brand-lightest;
    color: $brand-dark;

    .bannerIcon {
        color: $brand-dark;
    }
}

.banner:global(.warning) {
    border-color: $warning;
    background-color: $warning;
    color: $warning-dark;
}

.banner:global(.error) {
    border-color: $error;
    background-color: $error-lightest;
    color: $error;
}

.banner:global(.success) {
    border-color: $success;
    background-color: $success-light;
    color: $success;
}

.errorBanners {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
