@import "../../config.scss";
@import "../../../config.scss";

.tabBar {
    padding-inline-start: $spacing-3x;
    margin: 0 $spacing-half $spacing-1x 0;
    list-style: none;
    border-bottom: 5px solid $border-lighter;
    font-weight: 600;
    display: flex;
}

.tabContainer {
    margin: 0 $spacing-3x 0 0;
}

.tab {
    cursor: pointer;
    position: relative;
    background-color: $background-sand;
    top: 5px;
    left: 0;
    color: $text-light;
    letter-spacing: $letter-spacing;
    text-transform: capitalize;
    padding: 0 4px 12px;
    border: 0;

    &:focus {
        outline: none;
        border-bottom: 5px solid $highlight-primary-lighter;
    }
}

.tabActive {
    border-bottom: 5px solid $highlight-primary !important;
    color: $highlight-primary;
}
