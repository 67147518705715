@import "../config.scss";
@import "form.scss";

.pageBreakTop {
    margin-top: $spacing-3x;
}

.form {
    max-width: 650px;
    width: 80%;
    margin-bottom: 40px;
    margin-left: 100px;

    td {
        padding: $spacing-1x;
        vertical-align: center;
    }
}

.formLabel {
    width: 150px;
}

.attributeContainer {
    margin: 40px 0 40px 100px;
    max-width: 650px;
    width: 80%;
}

.infoContainer {
    max-width: 800px;
    width: 80%;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.faButtonLabel {
    margin-left: $spacing-1x;
}

.errorBanner {
    margin-bottom: $spacing-2x;
}
