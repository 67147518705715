@import "../../../../pre-v3/config.scss";

.dnsReport {
    display: flex;
}

.container {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4x;
}

.title {
    p {
        font-weight: 500;
        font-size: $border-radius-larger;
        color: $text-v3;
        margin: 0;
    }
}

.description {
    font-style: italic;
    color: $text-light;
}

.headerText {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $text-semidark;
    font-size: $font-size-smaller;
    font-weight: 600;
}

.time {
    font-weight: 600;
    font-size: $font-size-large;
    color: $text-dark;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    column-gap: $spacing-1x;
}
