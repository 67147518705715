.formRowContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.formLabel {
    gap: 4px;
    font-size: 14px;
}

.formRowContent {
    width: 400px;
}
