@import "../../../pre-v3/config.scss";

.button {
    padding: 0;
    color: $blue-500;
    font-weight: 500;
    border: none;
    background: transparent;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        color: $blue-600;
    }

    &.brand {
        color: $brand;

        &:hover,
        &:focus,
        &:active {
            color: $blue-600;
        }
    }
}
