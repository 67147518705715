@use "sass:math";

// PRIVATE

// red
$red-50: #f2dede;
$red-500: #e52f18;
$red-600: #d91901;
$red-800: #aa1300;
$red-900: #8f1b0d;

// orange
$orange-600: #f07402;

// yellow
$yellow-50: #fdfaec;
$yellow-500: #fdce3a;
$yellow-600: #ebb60d;
$yellow-800: #616018;

// green
$green-50: #e9f8f2;
$green-500: #00c884;
$green-600: #00a66d;
$green-800: #00724b;

// cyan
$cyan-50: #f2fdff;
$cyan-100: rgb(40 194 219 / 18.8%);
$cyan-200: rgb(40 194 219 / 40%);
$cyan-400: #22b9d7;
$cyan-500: #23b9d8;
$cyan-600: #2ca3b8;

// blue
$blue-50: #f1f7ff;
$blue-100: #c9ddfa;
$blue-200: #a4c8fb;
$blue-300: #83b0f2;
$blue-400: #5e91da;
$blue-500: #3f71b8;
$blue-600: #28599e;
$blue-700: #184381;
$blue-800: #092c5e;
$blue-900: #011c42;

// bluegray
$bluegray-50: #f6f7fb;
$bluegray-100: #eaebf2;
$bluegray-200: #d7d9e4;
$bluegray-300: #bbbecf;
$bluegray-400: #a2a5b7;
$bluegray-500: #84889f;
$bluegray-600: #686c82;
$bluegray-700: #484c60;
$bluegray-800: #2f3243;
$bluegray-900: #232635;
$black: #222c36;
$white: #fff;
$grey: #ccc;
$verydark: #031828;

// PUBLIC

// background-colors
$bg-lightest: $white;
$bg-lighter: $bluegray-50;
$bg-light: $bluegray-100;
$bg-semidark: $bluegray-200;
$bg-dark: $bluegray-600;
$bg-darker: $bluegray-700;
$bg-darkest: $black;

// text-colors
$text-lightest: $white;
$text-lighter: $bluegray-100;
$text-light: $bluegray-400;
$text-semidark: $bluegray-500;
$text-dark: $bluegray-700;
$text-darker: $bluegray-800;
$text-darkest: $bluegray-900;
$text-placeholder: $bluegray-300;

// border-colors
$border-lighter: $bluegray-100;
$border-light: $bluegray-200;
$border-dark: $bluegray-300;
$border-darker: $bluegray-600;

// brand
$brand-lightest: $cyan-50;
$brand-lighter: $cyan-200;
$brand: $cyan-500;
$brand-darker: $cyan-600;
$brand-dark: $verydark;

// highlight
$highlight-primary-lightest: $bluegray-100;
$highlight-primary-lighter: $blue-300;
$highlight-primary-light: $blue-400;
$highlight-primary: $blue-500;
$highlight-primary-dark: $blue-600;
$highlight-primary-darker: $blue-700;

// success
$success-lightest: $green-50;
$success-dark: $green-500;
$success-darker: $green-600;
$success-darkest: $green-800;

// error
$error-light: $red-50;
$error-dark: $red-500;
$error-darker: $red-600;
$error-darkest: $red-800;

// warning
$warning-lightest: $yellow-50;
$warning-dark: $yellow-500;
$warning-darker: $yellow-600;
$warning-darkest: $yellow-800;

// hover
$hover-question-button: #f4f4f4;

// font
$font-size-smallest: 10px;
$font-size-smaller: 11px;
$font-size-small: 12px;
$font-size: 14px;
$font-size-large: 16px;
$font-size-h6: 18px;
$font-size-larger: 22px;
$font-size-largest: 28px;

// spacing
$spacing-1x: 8px;
$spacing-2x: $spacing-1x * 2;
$spacing-3x: $spacing-1x * 3;
$spacing-4x: $spacing-1x * 4;
$spacing-5x: $spacing-1x * 5;
$spacing-6x: $spacing-1x * 6;
$spacing-10x: $spacing-1x * 10;
$spacing-12x: $spacing-1x * 12;
$spacing-half: math.div($spacing-1x, 2);

// letter-spacing
$letter-spacing: 0.2px;
$letter-spacing-large: 0.5px;
$line-height: 1.5em;

// border-radius
$border-radius-small: 2px;
$border-radius: 4px;
$border-radius-large: 8px;
$border-radius-larger: 24px;

// v3

// text-v3
$text-lightest-v3: #fff;
$text-light-v3: #a2a5b7;
$text-v3: #232635;

// background-v3
$background-lightest-v3: #fff;
$background-light-v3: #eaebf2;

// border-v3
$border-lightest-v3: #f6f7fb;
$border-light-v3: #bbbecf;
$border-v3: #686c82;

// brand-v3
$brand-lightest-v3: #f2fdff;
$brand-v3: #23b9d8;
$brand-dark-v3: #2ca3b8;

// primary-v3
$primary-v3: #3f71ba;
$primary-dark-v3: #28599e;

// success-v3
$success-lightest-v3: #e9f8f2;
$success-v3: #00c884;

// warning-v3
$warning-lightest-v3: #fdfaec;
$warning-v3: #fdce3a;

// error-v3
$error-lightest-v3: #f2dede;
$error-v3: #e52f18;

// highlight-v3
$highlight-v3: #f07402;
