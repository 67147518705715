@import "../../../config.scss";
@import "../../../styles/page.scss";
@import "../../../styles/dynamic-form.scss";

.formFields {
    max-width: 800px;
}

.policyAttribute {
    margin-left: $spacing-1x !important;
    min-width: 300px;
    max-width: 650px;
    width: 80%;
}

.download {
    margin-top: $spacing-4x;
    display: block;
}

.boldText {
    font-weight: 700;
}

.boldText::after {
    content: "\00a0";
}

.policyForm {
    padding-top: 0 !important;
    padding-left: $spacing-1x;
}
