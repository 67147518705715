@import "../../../config.scss";

.formFields {
    max-width: 800px;
}

.attribute {
    color: $text-semidark;
    margin-bottom: $spacing-2x;
}

.enforcementLabel {
    margin-top: $spacing-2x;
    color: $text-semidark;
}

.hostInput {
    width: 80%;
}

.sectionContainer {
    margin-top: $spacing-2x;
    width: 80%;
}

.download {
    margin-top: $spacing-2x;
    display: block;
}

.label,
.outerLabel {
    margin-left: 0 !important;
}

.outerLabel {
    margin-bottom: $spacing-2x !important;
}

.innerLabel {
    margin: 0 !important;
}

.input {
    margin-top: $spacing-2x;
}

.corsHeader {
    margin-bottom: $spacing-2x !important;
}

.corsExemption {
    margin-left: $spacing-3x;
    margin-bottom: $spacing-2x !important;
}

.advancedSummary:last-child {
    padding-bottom: $spacing-2x !important;
}

.userFacingSpacing {
    margin-top: $spacing-2x !important;
}

.textArea {
    resize: vertical;
}
