.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 0;
}

.tabContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
}

.hidden {
    visibility: hidden;
    height: 0 !important;
}

.searchInput {
    width: 250px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
