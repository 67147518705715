@use "sass:map";
@import "../../../styles.scss";

$variants: (
    "default": (
        "bg": $bg-lightest,
        "borderColor": $border-dark,
        "color": $text-darkest,
        "hoverBg": $bg-lightest,
        "hoverBorderColor": $border-darker,
        "hoverColor": $brand,
    ),
    "brand": (
        "bg": $brand,
        "borderColor": transparent,
        "color": $text-lightest,
        "hoverBg": $brand-dark,
    ),
    "error": (
        "bg": $error,
        "borderColor": transparent,
        "color": $text-lightest,
        "hoverBg": $error-dark,
    ),
);

@mixin fill-variant-style($variantValue) {
    background-color: #{map.get($variantValue, "bg")};
    color: #{map.get($variantValue, "color")};
    border-color: #{map.get($variantValue, "borderColor")};

    &:hover {
        background-color: #{map.get($variantValue, "hoverBg")};
        color: #{map.get($variantValue, "hoverColor")};
        border-color: #{map.get($variantValue, "hoverBorderColor")};
    }
}

.variant {
    border-width: 1px;
    border-style: solid;

    &[disabled] {
        pointer-events: none;
    }

    &[disabled]:not(.loading) {
        background-color: $bg-light;
        color: $text-dark;
        border-color: $border-dark;
    }

    @each $variantName, $variantValue in $variants {
        &.#{$variantName} {
            @include fill-variant-style($variantValue);
        }
    }
}
