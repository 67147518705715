@use "../../../styles.scss";

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: 8px 24px;
    border-radius: styles.$border-radius;
    cursor: pointer;
    text-align: center;
}

.loadingIcon {
    margin-right: 8px;
}
