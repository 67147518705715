@import "../../../styles.scss";

.status {
    padding-left: 4px;
}

.errorContainer {
    padding-bottom: 24px;
}

.formButtons {
    display: flex;
    gap: 16px;
}

.noStatus {
    text-align: center;
    margin: 100px 0;
}

.description {
    @include italic-text;
    color: $text-dark !important;
    padding-bottom: 8px;
}

.adminGrid {
    margin-top: 32px;
}

.errorBanner {
    margin-top: 24px;
}
