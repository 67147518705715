@import "../../../styles.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.rowContainer {
    display: flex;
}

.checkboxItem {
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    display: flex;
    grid-row: 1;

    &:not(:first-of-type) {
        border-left-width: 0;
    }

    &:first-of-type {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    &:last-of-type {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    &.borderOverride {
        border-color: $border-dark;

        &:hover {
            border-color: $border-dark;
        }
    }
}
