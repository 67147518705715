@import "../../styles.scss";

.reg {
    font-size: $icon-size-normal;
}

.xs {
    font-size: $icon-size-extra-small;
}

.sm {
    font-size: $icon-size-small;
}

.lg {
    font-size: $icon-size-large;
}

.xl {
    font-size: $icon-size-extra-large;
}
