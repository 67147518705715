@import "../config.scss";

.overviewContainer {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: $spacing-1x $spacing-3x $spacing-1x $spacing-2x;
}

.overviewLeftContainer {
    width: 32%;
    min-width: 325px;
    padding-left: $spacing-1x;
}

.overviewRightContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.overviewRightGridContainer {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.missingMessage {
    margin-top: 10%;
    width: 100%;
}

@media (max-width: 1000px) {
    .overviewLeftContainer {
        width: 100%;
    }

    .overviewRightContainer {
        flex: unset;
        margin-top: 50px;
        height: 600px;
        width: 100%;
    }
}

.statusTable {
    margin-top: $spacing-3x;

    td {
        padding: 4px !important;
        font-weight: 500;
    }
}

.simpleTable {
    width: 90%;
}

.statusBlock {
    text-align: left;
    padding: 18px 16px;
    border: 1px solid;
    border-radius: $border-radius;
    margin-bottom: $spacing-2x;
    width: 90%;

    &.success {
        border-color: $success-dark;
        background-color: $success-lightest;
    }

    &.warning {
        border-color: $warning-dark;
        background-color: $warning-lightest;
    }

    &.error {
        border-color: $error-dark;
        background-color: $error-light;
    }

    &.inactive {
        border-color: $text-dark;
        background-color: $text-lighter;
    }
}

.statusLabel {
    color: $text-semidark;
    font-weight: 500;
    font-size: $font-size-smaller;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

.statusContent {
    font-weight: 600;
    font-size: $font-size-large;

    svg {
        margin: 3px 0 0 2px;
    }
}

.toolbarContainer {
    padding-left: 20px;
    padding-bottom: 20px;
}

.detailTableHeader {
    padding: $spacing-1x $spacing-2x;
    padding-right: 45px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $text-semidark;
    border-bottom: 1px solid $border-light;
    font-size: $font-size-smaller;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.detailTableRow {
    padding: 12px 0 12px $spacing-2x;
    border-bottom: 1px solid $border-light;
    display: flex;
    flex-direction: row;
}

.iconTableCell {
    width: 5%;
}

.detailTableCell {
    width: 40%;
}

.detailTable {
    margin-bottom: 40px;
}
