@import "../../../../config.scss";
@import "../../../../styles/page.scss";
@import "../../../../styles/dynamic-form.scss";

.formLabel {
    width: 190px;
    text-align: right;
}

.submitSection {
    margin-left: 48px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    padding-bottom: 0px;
}

.container {
    padding: 0 24px 24px 24px;
}
