@import "../../config.scss";

.link {
    display: inline;
    border: 0;
    color: $brand-600;
    background-color: transparent;

    &:hover {
        color: $brand-800;
    }
}
