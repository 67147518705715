.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}

.upgradeLink {
    margin: 0 4px;
}

.filterContainer {
    width: 100%;
    display: inline;
    margin-right: 10px;
}

.container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    justify-items: end;
    column-gap: 16px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        justify-items: stretch;
        row-gap: 8px;
    }
}

.filterBar {
    padding-right: 10px;
}

.error {
    margin: 24px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
