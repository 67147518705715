.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
}
.search {
    display: flex;
    gap: 16px;
}
.grid {
    flex: 1;
}
