@import "../../../config.scss";
@import "../../../styles/form.scss";
@import "../../../styles/page.scss";

.header {
    margin-bottom: $spacing-4x;
}

.contentHeader {
    margin-bottom: $spacing-4x;
    margin-top: $spacing-5x;
}

.form {
    width: 60%;
    max-width: 2000px;
}

.button {
    width: 200px;
}

.appHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}
