@import "../../../../styles.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.statusInfo {
    border: 1px solid;
    border-radius: $border-radius-large;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    min-width: 350px;
    height: auto;
    width: 33.33%;

    &.success {
        border-color: $success;
        background-color: $success-light;
    }

    &.warning {
        border-color: $warning;
        background-color: $warning-light;
    }

    &.error {
        border-color: $error;
        background-color: $error-lightest;
    }

    &.disabled {
        border-color: $text-dark;
        background-color: $text-lighter;
    }
}

.statusLabel {
    @include small-uppercase-text;
}

.columnFlexContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
}

.flexGap6x {
    gap: 6px;
}

.flexGap8x {
    gap: 8px;
}
