@import "../../../../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
