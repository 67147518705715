@import "../../config.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $spacing-4x;
    margin: auto;
    max-width: 1280px;
}

.titleBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.dashboard {
    margin-top: $spacing-3x;
    flex: 1;
    width: 100%;
}

.learnMoreContainer {
    border: 1px solid $border-light;
    box-shadow: 0 0 4px 1px rgb(3 24 40 / 15%);
    border-radius: $border-radius-large;

    margin-top: $spacing-3x;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../../images/emptystate-dashboard-background.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    .contentContainer {
        max-width: 500px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-light;
        box-shadow: 0 0 4px 1px rgb(3 24 40 / 15%);
        border-radius: $border-radius-large;
        padding: 50px;

        .content {
            font-size: $font-size-large;
            font-weight: 500;
            margin-bottom: $spacing-4x;
            text-align: center;
        }

        .link {
            font-size: $font-size-large;
            font-weight: 500;
        }
    }
}

.dashboardGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 110px;
    column-gap: $spacing-2x;
    margin-bottom: $spacing-2x;
    grid-template-areas: "device-count user-count service-count policy-count role-count at-count";
}
