@import "../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    border: 1px solid $border-dark;
    border-radius: $border-radius;
}

.filterMenuButton {
    opacity: 0;
    width: 1px;
    height: 40px;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.filterView,
.filterMenu {
    min-width: 400px;
}

.input {
    flex: 1;
    border: 0 !important;
    padding: 8px 12px;
}

.pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $spacing-1x;
    background-color: $bg-light;
    border: 1px solid $border-dark;
    border-radius: 20px;
}

.label {
    cursor: pointer;
    padding-left: $spacing-2x;
    padding-top: $spacing-1x;
    padding-bottom: $spacing-1x;
    white-space: nowrap;
}

.iconContainer {
    cursor: pointer;
    padding: $spacing-1x 12px $spacing-1x $spacing-1x;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toolbarSearchContainer {
    width: 250px;
    display: inline-block;
    margin-right: $spacing-1x;
}
