@import "../../config.scss";

.inputRow {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-1x;
}

.input {
    flex: 1;
    margin-right: $spacing-1x;
}

.button {
    width: 50px;
}
