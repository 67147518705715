@import "../../../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.statusIcon {
    display: inline-block;
    font-size: 20px;
    margin-right: $spacing-1x;
}

.statusIconEnforcing {
    color: $success-dark;
}

.statusIconPermissive {
    color: $warning-dark;
}

.statusIconNoPolicy {
    color: $error-dark;
}

.statusIconInactive {
    color: $text-darker;
}

.statusLabel {
    font-weight: 600;
}
