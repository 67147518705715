@import "../../../../../../config.scss";

.section {
    height: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-bottom: 0;
}

.pageBreak {
    margin: 0;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-bottom: 24px;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.details {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 1fr;
    row-gap: 24px;
}

.horizontalLine {
    width: 100%;
    border: 1px solid $bluegray-500;
}

.saveButton {
    align-self: flex-start;
}
