@import "../../../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.userHeader {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

:global(div) {
    div:has(> div.userHeader) {
        margin: 0;
        flex-grow: 1;
    }
    div:has(> div.formColumn) {
        margin: 0;
        flex-grow: 1;
    }
}

.description {
    @include italic-description-text;
    max-width: 800px;
    margin-top: 8px;
}

.button {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 24px;
}

.tokenContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.token {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 49%;
}

.copyText {
    width: 100%;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 700px;
}

.tableContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.columnHeader {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
}

.endUserContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: transparent;
    h2 {
        @include h4-numeric-card-title;
    }

    p {
        @include card-header-description-text;
    }

    .titleDescription {
        display: flex;
        gap: 8px;
    }
}

.titleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 50%;
}
