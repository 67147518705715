@import "../config.scss";

.pageWithLeftNav {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.pageRightWithActionBar {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.pagePadded {
    padding: 0 $spacing-3x $spacing-4x $spacing-3x;
}

.pageContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    overflow: auto;
}

.pageContentPadded {
    width: 65%;
    min-width: 600px;
    margin: auto;
    padding: 30px;
}
