.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0 24px;
}

:global(.addIdpRouted) {
    background-image: url("../../../../images/idprouted-template.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

:global(.addSassService) {
    background-image: url("../../../../images/banyanfed-template.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search {
    margin: 0;
}

.grid {
    flex: 1;
}
