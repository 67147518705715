@import "../../../../config.scss";

.form {
    gap: 24px;
}

.container {
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.deleteContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
