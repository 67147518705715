@import "../../../config.scss";

.leftNavItemDivider {
    margin: $spacing-3x 0 $spacing-1x $spacing-3x;
    font-weight: 400;
    font-size: $font-size-smaller;
    color: $text-light;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
}

.leftNavItemLink {
    display: block;
    padding: $spacing-1x $spacing-3x;
    width: 100%;
    color: $text-darker;

    &:hover {
        color: $text-darker;
        background-color: $bg-light;
    }

    &:focus {
        color: $text-darker;
        outline: none;
        text-decoration: none;
    }
}

.activeLink {
    border-left: 6px solid $brand;
    padding-left: 18px;
    background-color: $bg-light;
}

.leftNavItemIcon {
    display: inline-block;
    padding-right: 12px;
    text-align: right;
    width: 26px;

    svg {
        vertical-align: middle;
        font-size: 0.875em;
    }
}

.leftNavItemCollapsed {
    .leftNavItemLink {
        padding: $spacing-2x $spacing-3x;
    }

    .activeLink {
        padding-left: $spacing-2x;
    }
}
