.accordionContent {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.header {
    align-items: center;
}

.flex {
    display: flex;
    justify-content: flex-end;
}
