@import "../../config.scss";

.button {
    display: inline-block;
    background-color: $bg-lightest;
    color: $text-dark;
    padding: 9px 15px;
    border-radius: $border-radius;
    border: 1px solid $border-light;
    cursor: pointer;

    &:hover {
        background-color: $bg-light;
        border-color: $border-dark;
    }

    &:focus {
        outline: 0;
        border-color: $border-dark;
    }

    &:active {
        border-color: $border-dark;
    }

    &[disabled] {
        background-color: $bg-lighter;
        pointer-events: none;
    }

    &.brand {
        background-color: rgba($brand, 0.7);
        border: 2px solid $brand;
        color: $text-lightest;
        font-size: $font-size-smaller;
        font-weight: 600;
        padding: 10px $spacing-2x;
        letter-spacing: $letter-spacing;
        border-radius: 20px;

        &:hover,
        &:focus {
            background-color: $brand-dark;
        }

        &:active {
            background-color: $brand;
        }

        &[disabled] {
            background-color: rgba($brand, 0.3);
        }
    }

    &.primary {
        background-color: $highlight-primary;
        color: $text-lightest;
        border: 0;
        padding: 10px $spacing-2x;

        &:hover {
            background-color: $highlight-primary-dark;
        }

        &:focus {
            background-color: $highlight-primary-dark;
        }

        &:active {
            background-color: $highlight-primary-darker;
        }

        &[disabled] {
            background-color: $highlight-primary-lighter;
        }
    }

    &.error {
        background-color: $error-darker;
        color: $text-lightest;
        border: 0;
        padding: 10px $spacing-2x;

        &:hover {
            background-color: $error-darkest;
        }

        &:focus {
            background-color: $error-darkest;
        }

        &:active {
            background-color: $error-darkest;
        }

        &[disabled] {
            background-color: $error-light;
        }
    }

    &.textPrimary {
        background-color: transparent;
        color: $highlight-primary-light;
        border: none;

        &:hover {
            color: $highlight-primary;
        }
    }
}

.icon {
    margin-right: 6px;
}
