@import "../../../styles.scss";

.container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    column-gap: 8px;
}

.htmlInput {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
}

.disabled {
    color: $text-dark;
}
