@use "sass:meta";

@import "../../../../../../../pre-v3/config.scss";

.summary {
    column-gap: $spacing-2x;
}

.labelContainer {
    flex: 1;
    display: flex;
    column-gap: 4px;
}

.label {
    font-weight: 700;
    font-size: 16px;
}

.factorDetailsContainer {
    display: grid;
    grid-template-columns: repeat(3, 185px);
    align-items: center;
    justify-items: center;
}

.factorSource {
    text-align: center;
}

.trustEffectLabel {
    display: flex;
    column-gap: $spacing-1x;
    text-transform: uppercase;
}

$trustEffectCircleSize: 15px;

.trustEffectCircle {
    width: $trustEffectCircleSize;
    height: $trustEffectCircleSize;
    border-radius: 50%;
}

@mixin effectClasses($args...) {
    @each $name, $color in meta.keywords($args) {
        .trustEffectCircle.#{$name} {
            background: $color;
        }
    }
}

@include effectClasses(
    $notEvaluated: $bluegray-300,
    $alwaysDeny: $red-900,
    $lowTrustLevel: $red-500,
    $mediumTrustLevel: $yellow-500,
    $noEffect: $green-500
);

.trustEffectContainer {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-2x;
}

.complexConfiguration {
    grid-column: 1 / -1;
    margin-top: 26px;
    border-top: 1px solid $border-light;
    padding: 30px 0 calc(30px - 16px);
}
