@import "../../../../styles.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
}

.sectionContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
}

.networkContainer {
    display: flex;
    column-gap: 8px;
    align-items: baseline;
}

.header {
    display: flex;
    justify-content: space-between;
}

.network {
    @include small-uppercase-text;
}

.titleAndSub {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
