@import "../styles.scss";
@import "./config.scss";

.pre-v3 {
    h2,
    h3 {
        color: $text-darkest;
    }

    [hidden] {
        display: none !important;
    }

    input[type="checkbox"]:not(:disabled) {
        cursor: pointer;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    input[type="url"],
    textarea {
        @include border-dark;
        @include regular-text;

        padding: 8px 12px;

        &:disabled {
            background-color: $bg-light;
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    textarea {
        line-height: 1.4em;
    }

    textarea:disabled {
        background-color: $bg-light;
        color: $text-semidark;
    }

    svg.svg-inline--fa {
        vertical-align: top;
    }

    /* Remove after we remove all instances of pre-v3 inputs and selects */
    ::placeholder {
        color: $border-light-v3;
    }

    .token.operator {
        background: none;
    }

    .nav-menu-new-icon {
        padding-left: $spacing-half;
        color: $brand;
        text-transform: uppercase;
        font-size: $font-size-small;
        font-weight: bold;
        font-style: italic;
    }

    ul {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
    }

    p {
        margin: 0;
        margin-block-start: 0;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
    }

    hr {
        border-top: 1px solid $border-lighter;
    }
}
