@import "../../config.scss";
@import "../../styles/form.scss";

.banner {
    margin-bottom: $spacing-2x;
}

.oidcLogin {
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.loginForm {
    width: 50%;
    min-width: 400px;
    margin-top: 5%;

    .form {
        position: relative;
        z-index: 1;
        background: #fff;
        border-radius: $border-radius;
        max-width: 400px;
        margin: 0 auto 100px;
        padding: 45px;
        text-align: center;
        box-shadow:
            0 0 80px 0 rgb(0 0 0 / 8%),
            0 4px 4px 0 rgb(0 0 0 / 4%);

        input {
            outline: 0;
            background: $bg-lighter;
            width: 100%;
            border: 0;
            border-radius: $border-radius;
            margin: 0 0 $spacing-2x;
            padding: $spacing-2x;
            box-sizing: border-box;

            .domain {
                width: 60%;
                border-radius: 4px 0 0 4px;
            }

            &:focus {
                outline: rgb(0 95 204) auto 1px;
                border-color: transparent;
            }
        }

        .submitButton {
            width: 100%;
            text-transform: uppercase;
            padding: 15px !important;
        }

        .orgname {
            text-align: center;
            margin: 0 0 24px $spacing-1x;
        }

        .orgname strong {
            letter-spacing: 1px;
            font-weight: 500;
        }

        .orgnameinput {
            display: flex;
        }

        .inputurl {
            margin: 0 0 $spacing-2x;
            padding: $spacing-2x;
            line-height: 18px;
            width: 45%;
            border-radius: 0 4px 4px 0;
            background-color: #d9e3e5;
            font-size: 14px;
            letter-spacing: 0.3px;
            color: #444;
        }

        .message {
            margin: $spacing-1x 0 0;
            color: #aaa;

            a {
                color: #23b9d8;
                font-weight: 500;
                text-decoration: none;
            }
        }

        .register-form {
            display: none;
        }

        .logo {
            width: 80%;
            align-self: center;
            margin: 50px 0;
        }
    }
}

.link {
    cursor: pointer;
    color: #23b9d8;
    font-weight: 500;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
}
