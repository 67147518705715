@import "../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    font-weight: bold;
}

.statusIcon {
    display: inline-block;
    vertical-align: top;
    margin-right: $spacing-1x;
    font-size: $font-size-larger;
}

.success {
    color: $success-dark;
}

.inactive {
    color: $text-semidark;
}

.error {
    color: $error-dark;
}

.pending {
    color: $warning-dark;
}
