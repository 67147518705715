@import "../../../../styles.scss";

.menuItem {
    padding: 16px 24px;
}

.menuItemClickable {
    cursor: pointer;

    &:hover {
        background-color: $brand-dark;
        color: $text-lightest;
    }
}

.menuItemDivider {
    background-color: $bg-light;
    text-transform: uppercase;
    padding: 16px 24px 8px;

    @include small-placeholder-text;
}

.icon {
    margin-right: 8px;
}

.loading {
    text-align: center;
}
