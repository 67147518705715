@import "../../../pre-v3/config";
@import "../../../pre-v3/styles/page.scss";
@import "../../../pre-v3/styles/input-list.scss";

.icon {
    margin-right: $spacing-1x;
}

.toggle {
    width: 100%;
    text-align: left;
}

.section {
    margin-top: $spacing-4x;
    max-width: 750px;
    min-width: 300px;
    width: 60%;
}
