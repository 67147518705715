@import "../../../../pre-v3/styles/grid.scss";

:global(.webPolicy) {
    background-image: url("../../../../images/standardweb-template.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

:global(.infrastructurePolicy) {
    background-image: url("../../../../images/tcp-template.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

:global(.tunnelPolicy) {
    background-image: url("../../../../images/tunnel-policy-icon.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

:global(.jsonTemplate) {
    background-image: url("../../../../images/customtmpl.svg");
    background-repeat: no-repeat;
    background-position: 16px 50%;
}

.upgradeLink {
    margin: 0 4px;
}

.filterContainer {
    width: 100%;
    display: inline;
    margin-right: 10px;
}

.addButton {
    display: inline;
    margin-left: auto;
    white-space: nowrap;
}

.container {
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
        flex-direction: column-reverse;

        .addButton {
            padding-bottom: 10px;
        }
    }
}

.filterBar {
    padding-right: 10px;
}

.error {
    margin-top: 16px;
}

.search {
    width: 250px;
}

.filterButton {
    margin-left: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.outerContainer {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
