@use "sass:color";
@use "sass:meta";

@import "../../../pre-v3/config.scss";

$trackHeight: 3px;
$trackColor: #d9d9d9;

@mixin colorClasses($args...) {
    @each $name, $color in meta.keywords($args) {
        .#{$name}Button {
            &:hover {
                border-color: $color;
            }

            &.selected {
                border-color: $color;
                background: color.change($color, $alpha: 0.2);
            }
        }

        .visible.#{$name}Track {
            background-color: $color;
        }

        .#{$name}Thumb {
            background-color: $color;
        }

        .#{$name}Tick {
            height: $trackHeight;
            background-color: $color;
        }
    }
}

@mixin innerTrack {
    flex: 1;
    height: $trackHeight;
}

.disabled {
    pointer-events: none;
}

.container {
    display: grid;
    grid-auto-columns: 1fr;
}

.track {
    grid-row: 1;
    width: 100%;
    margin: 9px 0;
    display: flex;
    align-items: center;
}

.leftTrack {
    @include innerTrack;
}

.thumb {
    width: 18px;
    height: 18px;
    border: 3px solid $bluegray-400;
    border-radius: 9px;
}

.tick {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 3px;
    height: 9px;
    background: $trackColor;
    border-radius: 3px;
}

.rightTrack {
    @include innerTrack;

    &.visible {
        background-color: $trackColor;
    }
}

.labelButton {
    grid-row: 2;
    appearance: none;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 16px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
    color: $black;
    cursor: pointer;
    padding: 5.25px 6px;

    &:not(:first-of-type) {
        margin-left: 10px;
    }

    &:not(:last-of-type) {
        margin-right: 10px;
    }
}

// Matches colors in the component file
@include colorClasses(
    $red500: $red-500,
    $green500: $green-500,
    $bluegray300: $bluegray-300,
    $red900: $red-900,
    $yellow500: $yellow-500
);
