@import "../../../styles.scss";

.toggleButton {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    position: relative;
    z-index: 0;

    &:hover,
    &:focus {
        z-index: 2;
    }

    &.selected {
        z-index: 1;
    }

    &:not(:first-child) {
        margin-left: -1px;
    }

    &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(:first-child, :last-child) {
        border-radius: 0;
    }
}

.toggleButtonWrapper {
    display: flex;
}

.label {
    margin: 0;
}

.image {
    height: 60px;
}

.disabled {
    pointer-events: none;
}

.toggleWithImage {
    .label {
        max-width: 100px;
    }
}

.requiredInput {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    height: 0;
    width: 0;
    align-self: flex-end;
}
