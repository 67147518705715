.form {
    display: flex;
    flex-direction: column;
    padding: 32px 24px 48px;
    overflow: auto;
}

.formButtons {
    display: flex;
    gap: 16px;
}
