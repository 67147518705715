@import "../../../config.scss";

.card {
    border-radius: 12px;
    border: 1px solid $bluegray-800;
    padding: 24px;
    row-gap: 24px;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    @include h4-card-title;
}

.container {
    padding: 24px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

.textArea {
    height: 100px;
    resize: vertical;
}

.hiddenInput {
    position: relative;
    opacity: 0;
    padding: 0;
    top: 60px;
    margin-left: 25%;
}
