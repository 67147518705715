@use "sass:color";
@import "../../../pre-v3/config.scss";

.page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 15px 45px;
    width: 400px;
    height: 400px;
    margin-top: 8%;

    h2 {
        margin: 0;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
        color: color.change($verydark, $alpha: 0.7);
    }

    // Don't change until we redesign OIDC views
    input[type="text"],
    input[type="password"],
    input[type="email"] {
        padding: $spacing-2x !important;
    }

    // Don't change until we redesign OIDC views
    button,
    .button {
        text-transform: uppercase;
        padding: 15px !important;
    }
}

.logo {
    height: 50%;
    width: 80%;
    align-self: center;
    margin: 50px 0;
}

.errorBanner {
    margin-bottom: $spacing-2x;
}

.form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.spaceBelow {
    margin-bottom: 20px;
}

.textAlignCenter {
    text-align: center;
    margin-bottom: 8px;
}

.description {
    margin-top: $spacing-2x;
}

.userEmailAddress {
    font-weight: 600;
}
