.selectAll {
    padding: 0 8px;
}

.checkboxItem {
    padding: 8px;
}

.checkbox {
    position: relative;
    align-items: flex-start;
    flex: 1;
}

.checkboxChildren {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 4px;
    margin: 8px 32px;
    flex: 1;
}

.icon {
    position: absolute;
}
