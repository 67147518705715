@import "../../../pre-v3/config.scss";

.link {
    color: $brand;
}

.dictionaryLink {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: underline;
    text-decoration-style: dotted;
}
