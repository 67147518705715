@import "../../../config.scss";

.heading {
    color: $bluegray-1200;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
}

.privateAccessTiers {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;

    .label {
        color: $bluegray-900;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        padding-bottom: 8px;
    }
}

.privateContent {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .list {
        padding: 12px 16px;
        border-bottom: 1px solid $bluegray-400;
        border-top: 1px solid $bluegray-400;
    }
}
