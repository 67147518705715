@import "../../config.scss";

.pageBreak {
    margin: $spacing-3x 0 $spacing-2x 0;
}

.content {
    font-size: $font-size-small;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    color: $text-semidark;
    font-weight: 600;
    margin-top: 0;
}

.line {
    margin: 0;
    border-top: 2px solid $border-light;
}
