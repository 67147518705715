@import "../../../../config.scss";

.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.infoContainers {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 40px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.title {
    font-weight: 400;
    font-size: 11px;
    text-transform: uppercase;
    color: $bluegray-800;
}

.link {
    line-height: 16px;
}
