@import "../../config.scss";

.switch {
    display: inline-block;
    width: 58px;
    height: 24px;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 0 3px;
    background-color: $border-dark;
    cursor: pointer;

    &.disabled {
        background-color: $bg-semidark;
        opacity: 0.8;
        cursor: not-allowed;
    }

    &.active {
        background-color: $highlight-primary-dark;

        .knob {
            left: 34px;
        }
    }

    &.disabled.active {
        background-color: $highlight-primary-lighter;
    }
}

.knob {
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 18px;
    position: relative;
    top: 3px;
    left: 0;
    transition: left 0.1s ease-in;
}
