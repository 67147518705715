@import "../../../../pre-v3/config.scss";
@import "../../../../pre-v3/styles/grid.scss";

.input {
    width: 500px;
}

.platformSelection {
    padding-bottom: 20px;
}

.formSection {
    margin-bottom: 64px;
}

.formRow {
    display: flex;
    gap: 8px;
}

.button {
    margin-right: $spacing-1x;
}

.banner {
    width: 100%;
}

.ztaSeverity {
    width: 600px;
    margin-bottom: 64px;
}

.formLabel {
    margin-left: 24px !important;
}

.headerText {
    line-height: 0.5em;
    padding: $spacing-1x $spacing-2x;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $text-semidark;
    font-size: $font-size-smaller;
    font-weight: 600;
}

.lastEvaluated {
    font-weight: 600;
    font-size: $font-size-large;
    color: $text-dark;
    padding: $spacing-1x $spacing-2x;
    padding-right: 45px;
}

.syncStats {
    margin-top: $spacing-2x;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 5px;
    height: 200px;
}

.info {
    color: $highlight-primary;
}

.table {
    border-collapse: collapse;
    margin-left: $spacing-3x;
}

.tr {
    border-bottom: 1px solid $border-light;
}

.header {
    padding: 10px 0 10px 90px;
}

.text {
    text-align: center;
    color: $text-semidark;
    font-size: $font-size;
    font-weight: normal;
}

.fakeInput {
    position: absolute;
    z-index: -1;
    opacity: 0;
    bottom: $spacing-3x;
    left: $spacing-6x;
}

.fakeInputWrapper {
    position: relative;
}

.nameColumn {
    text-align: left;
    padding-left: $spacing-2x;
}

.nameCell {
    text-align: left;
}

.grid {
    min-height: 200px;
    padding-bottom: 20px;
}
