@import "../../config.scss";

.largeMessageContainer {
    text-align: center;
}

.largeMessageIcon {
    color: $text-darker;
    border: 3px solid $border-darker;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding-top: 6px;
    font-size: 16px;
}

.largeMessageText {
    font-size: $font-size-large;
    text-transform: capitalize;
}
