@import "../../config.scss";

.buttonsContainer {
    background-color: transparent;
    padding: 6px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: fit-content;
    border: solid 1px $bluegray-600;
}
.button {
    padding: 8px 16px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;

    background-color: transparent;
    border: none;
    color: $text-darkest;
    &:disabled {
        color: $gray-600;
    }
}
.selected {
    background-color: $bluegray-1000;
    color: $text-white;
    &:disabled {
        background-color: $gray-400;
        color: $gray-700;
        border: solid 1px $gray-400;
    }
}
