@import "../../../../styles.scss";

.checkbox {
    margin-top: 8px;
    margin-left: 8px;
}

.portInput {
    width: 65px;
}

.input {
    flex-grow: 1;
}

.container {
    display: flex;
    column-gap: 8px;
}

.dot {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
}
