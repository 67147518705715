@import "../../../config.scss";
@import "../../../styles/page.scss";

.container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
}
