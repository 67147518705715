@import "../../config.scss";

.Card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid $bluegray-800;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
    gap: 0;

    &.isOpen {
        gap: 36px;
    }
}

.expandLabel {
    color: $bluegray-1000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.CardContent {
    display: grid;
    grid-template-rows: 0fr;
    opacity: 0;
    transition: all 400ms ease;
    width: 100%;
    overflow: hidden;

    &.isOpen {
        grid-template-rows: 1fr;
        opacity: 1;
    }

    &.isChild {
        padding-top: 36px;
    }

    > div {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 36px;
    }
}

.CardDotted {
    border: 2px dashed $bluegray-1200;
}

.collapseLink button {
    display: block;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.cardAction {
    position: absolute;
    top: -24px;
    right: 24px;
}
