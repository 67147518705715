.gridWithToolbar {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    margin-left: auto;
}

.toolBar {
    margin-top: 0 !important;
}
