@import "../../../../../../../../pre-v3/config.scss";

$size: $spacing-5x;

.container {
    display: flex;
    align-items: flex-start;
    column-gap: $spacing-3x;
    padding: $spacing-2x;
    border: 2px solid $border-dark;
    border-radius: 4px;

    .content {
        flex: 1;

        h6 {
            margin: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 200%;
        }

        p {
            margin: 0;
            color: $text-semidark;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: $size;
        height: $size;
        background-color: $brand;
        color: $text-lightest;
        border: none;
        border-radius: 50%;
        font-size: $spacing-3x;

        &:active,
        &:focus,
        &:hover {
            background-color: $brand-darker;
        }
    }
}
