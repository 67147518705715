@import "../../config.scss";

.container {
    .paper {
        border-radius: $spacing-1x;
    }
}

.selectedIconContainer {
    display: inline-block;
    text-align: center;
    margin-right: $spacing-3x;
    margin-top: $spacing-1x;
}

.selectedIcon {
    font-size: 30px;
    color: $text-dark;
}

.button {
    vertical-align: 20px;
}

.toolbar {
    margin-top: 0;
    margin-bottom: $spacing-2x;
    margin-left: $spacing-2x;
    width: 90%;
}

.content {
    width: 650px;
    height: 350px;
    overflow-y: auto;
}

.iconContainer {
    display: inline-block;
    width: 90px;
    height: 100px;
    padding: $spacing-1x;
    vertical-align: top;
    text-align: center;
    cursor: pointer;
    color: $text-semidark;

    &:hover {
        color: $text-dark;
    }

    &.iconHighlight {
        color: $highlight-primary;
    }
}

.icon {
    font-size: 40px;
}

.iconName {
    padding-top: $spacing-1x;
    word-break: break-word;
    font-size: $font-size-smallest;
    margin-block-start: 0;
    margin-block-end: 0;
}
