@import "../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4x;
}

.title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.4em;
    color: $text-v3;
}

.description {
    font-style: italic;
    line-height: 1.4em;
    color: $text-light;
}

.headerText {
    text-transform: uppercase;
    letter-spacing: $letter-spacing;
    color: $text-semidark;
    font-size: $font-size-smaller;
    font-weight: 600;
    padding-bottom: 1em;
}

.lastEvaluated {
    font-weight: 600;
    font-size: $font-size-large;
    color: $text-dark;
}

.buttons {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}
