@import "../../../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 24px;
}

.description {
    font-weight: 500;
    font-size: 16px;

    p {
        margin-bottom: 8px;
    }
}

.buttonGroup {
    display: flex;
    gap: 8px;
}

.gridWithToolbar {
    padding-bottom: 24px;
    row-gap: 40px;
}

.errorContainer {
    margin-top: 24px;
}

.createButton {
    width: fit-content;
    margin-left: auto;
}

.pageBreak {
    margin: 0;
}

.tabBar {
    min-width: 800px;
    max-width: 1200px;
    gap: 16px;
    flex: 1;
}

.tabBarContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    min-height: 35px;
}

.statusInfo {
    display: block;
    padding: 24px;
    background: $card-light-gray;
    border: 1px solid $bluegray-700;
    border-radius: 8px;
    width: 500px;

    p {
        margin-bottom: 0;
    }

    height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.syncStatusContainer {
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    .statusContainer {
        background-color: $background-white;
        padding: 32px;
        height: 100px;
        width: 400px;
    }
}

.statusIcon {
    font-size: 14px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
