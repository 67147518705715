.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin-top: 24px;
    p {
        margin: 0;
    }
}

.alignRight {
    align-self: flex-end;
}

.buttonGroup {
    display: flex;
    column-gap: 8px;
}
