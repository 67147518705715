@use "sass:color";
@import "../../../styles.scss";
@import "../../../config.scss";

.step {
    grid-row: 1;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    background-color: $background-sand;
    border: none;
    border-bottom: 2px solid $border-dark;
    padding: 0 4px 12px;
    overflow: hidden;
}

.activeStep {
    border-bottom-color: $brand;
}

.disabledStep {
    color: $text-dark;

    .badge {
        background-color: $background-sand;
        color: $text-dark;
        border-color: $text-dark;
    }
}

.badge {
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
}

.label {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.clickable,
.clickable label {
    cursor: pointer;
}
