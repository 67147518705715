@import "../../../../config.scss";
@import "../../../../styles/form.scss";
@import "../../../../styles/input-list.scss";

.domainInput {
    width: calc(100% - 180px);
    margin-right: $spacing-1x;
    margin-bottom: $spacing-1x;
}

.portInput {
    width: 70px;
    margin-right: $spacing-1x;
    margin-bottom: $spacing-1x;
}

.checkbox {
    margin-right: $spacing-half !important;
}

.checkboxLabel {
    font-weight: normal;
    color: $text-semidark;
}
