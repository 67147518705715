.container {
    display: flex;
    flex-direction: row;
}

.label {
    position: relative;
    top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button {
    position: relative;
    top: -5px;
}
