// Gray Colors
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e9e9e9;
$gray-400: #d9d9d9;
$gray-500: #cccccc;
$gray-600: #bdbdbd;
$gray-700: #a9a9a9;

// Bluegray Colors
$bluegray-100: #f5f7fb;
$bluegray-200: #eceff8;
$bluegray-300: #e2e8f4;
$bluegray-400: #d9e0f1;
$bluegray-500: #cfd8ed;
$bluegray-550: #c0cae2;
$bluegray-600: #b0bbd6;
$bluegray-700: #9ca7c2;
$bluegray-800: #8793af;
$bluegray-900: #707d9b;
$bluegray-1000: #5a6787;
$bluegray-1100: #414c67;
$bluegray-1200: #2e3853;
$bluegray-1300: #2f3243;

// Brand Colors
$brand-100: #e1f7fb;
$brand-150: #ccf0f8;
$brand-200: #b3eaf5;
$brand-300: #83ddee;
$brand-400: #53cee6;
$brand-450: #33c4df;
$brand-500: #23b9d8;
$brand-600: #1faac5;
$brand-700: #1995aa;
$brand-800: #158192;
$brand-900: #11707c;
$brand-1000: #0d5f66;

// Text Colors
$text-white: #ffffff;
$text-darkest: #2f3243;
$text-ai-search: rgba($text-darkest, 0.75);

// Link Colors
$link-destructive: #e52f18;

// Error Button Colors
$error-button-default: #c13636;
$error-button-hover: #912929;
$error-button-active: #611b1b;

// Background Colors
$background-white: #ffffff;
$background-sand: #fbf8f8;
$background-darkest: #2f3243;

// Card Colors
$card-blue: $bluegray-500;
$card-gray: #d7d9e4;
$card-light-gray: #f1f1f1;
$card-box-shadow: rgba(0, 0, 0, 0.25);

// Feedback Colors
$feedback-success-light: #a5cd7e80;
$feedback-success-dark: #385f06;
$feedback-error-light: #d6908c80;
$feedback-error-dark: #912929;
$feedback-warning-light: #ffe1a880;
$feedback-warning-dark: #b1541f;
$feedback-info-light: #9bc7fa80;
$feedback-info-dark: #28599e;
$feedback-generic-1-light: #abb1b84c;
$feedback-generic-1-dark: #1f2937;
$feedback-generic-2-light: #a78cd680;
$feedback-generic-2-dark: #5f30b0;

// Accent Colors
$shadow-green: #99c7c7;
$variable-color: #a48d61;

//box shadow colors
$card-box-shadow: #00000040;

// Font Families
$font-family-serif: "Libre Caslon Text", serif;
$font-family-sans-serif: "Red Hat Display", sans-serif;
$font-family-monospace: "Fira Code", monospace;

// z-index
$left-nav-bar-z-index: 1;
$msw-csc-z-index: $left-nav-bar-z-index + 1;

@mixin h3-ai-search-title {
    color: $text-ai-search;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 14px;
}

@mixin normal-body-text {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 13px;
}

@mixin small-body-text {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin h4-numeric-card-title {
    color: $text-darkest;
    font-family: $font-family-serif;
    font-weight: 700;
    font-size: 18px;
}

@mixin h4-card-title {
    color: $text-darkest;
    font-family: $font-family-serif;
    font-weight: 600;
    font-size: 14px;
}

@mixin card-heading {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 16px;
}

@mixin h5-widget-subtitle {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin h6-widget-subtitle {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin card-small-body-text {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin card-large-body-text {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 16px;
}

@mixin card-table-body-text {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 12px;
}

@mixin card-table-body-subtext {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin table-column-heading {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
}

@mixin accordion-heading-title {
    color: $text-darkest;
    font-family: $font-family-serif;
    font-weight: 600;
    font-size: 18px;
}

@mixin accordion-subsection-subtitle {
    color: $bluegray-1000;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 12px;
}

@mixin form-field-info-title {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 13px;
}

@mixin form-field-info-help-text {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 14px;
}

@mixin text-field-placeholder-text {
    color: $bluegray-700;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin text-field-disabled-text {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin text-field-active-text {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin text-field-inactive-text {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin normal-link {
    color: $brand-600;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 13px;
}

@mixin destructive-link {
    color: $link-destructive;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 13px;
}

@mixin success-banner {
    color: $feedback-success-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin error-banner {
    color: $feedback-error-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin warning-banner {
    color: $feedback-warning-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin info-banner {
    color: $feedback-info-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin generic-1-banner {
    color: $feedback-generic-1-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin generic-2-banner {
    color: $feedback-generic-2-dark;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin label-success {
    color: $feedback-success-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-error {
    color: $feedback-error-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-warning {
    color: $feedback-warning-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-info {
    color: $feedback-info-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-generic-1 {
    color: $feedback-generic-1-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-generic-2 {
    color: $feedback-generic-2-dark;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin label-dark {
    color: $text-white;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 10px;
}

@mixin button-active-light {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin button-active-dark {
    color: $text-white;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin button-inactive {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
}

@mixin toggle-active-selected-tab {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin toggle-active-unselected-tab {
    color: $bluegray-800;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin toggle-active-inactive-tab {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin toggle-inactive-selected-tab {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin toggle-inactive-unselected-tab {
    color: $gray-500;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin pill-active {
    color: $text-darkest;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;

    &:hover {
        color: $text-white;
    }
}

@mixin pill-inactive {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin small-button-row-active {
    color: $bluegray-1000;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin small-button-row-inactive {
    color: $gray-700;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 12px;
}

@mixin code-text-active {
    color: $text-darkest;
    font-family: $font-family-monospace;
    font-weight: 500;
    font-size: 14px;
}

@mixin code-text-inactive {
    color: $gray-700;
    font-family: $font-family-monospace;
    font-weight: 500;
    font-size: 14px;
}

@mixin shadow {
    box-shadow: 0px 4px 4px 0px $card-box-shadow;
}

@mixin italic-description-text {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #a2a5b7;
}

@mixin border-disabled {
    border: 1px solid $gray-700;
    border-radius: 4px;
}

@mixin border-inactive {
    border: 1px solid $bluegray-700;
    border-radius: 4px;
}

@mixin border-active {
    border: 1px solid $bluegray-800;
    border-radius: 4px;
}

@mixin card-header-description-text {
    font-size: 14px;
    font-weight: 400;
    color: $bluegray-1300;
    font-family: $font-family-sans-serif;
}
