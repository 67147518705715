@import "../../../../config.scss";
@import "../../../../styles/form.scss";

.verticalSpacing {
    margin-top: $spacing-4x;
}

.inputContainer {
    margin-top: $spacing-2x;
    width: 90%;
}
