@import "../../../styles.scss";

.default {
    font-size: $icon-size-normal;
}

.small {
    font-size: $icon-size-small;
}

.large {
    font-size: $icon-size-large;
}
