@import "../../config.scss";

.success {
    color: $feedback-success-dark;
    pointer-events: none;
}
.error {
    color: $feedback-error-dark;
    pointer-events: none;
}

.header {
    display: flex;
    flex-direction: row;
    background: $background-white;
    padding: 10px 15px;
    margin-bottom: 0;
    border-radius: 12px 12px 0 0;
    border: 1px solid $bluegray-800;
}

.label {
    font-size: 14px;
    font-weight: 400;
}

.content {
    background: $text-darkest;
    font-size: 14px;
    font-weight: 400;
    color: $text-white;
    padding: 24px;
    margin-bottom: 0;
    border-radius: 0 0 12px 12px;
    overflow-x: auto;

    .text {
        font-family: $font-family-monospace;
    }
}

.button {
    padding: 0;
    margin-left: auto;
    cursor: pointer;
    font-size: 14px;
    border: $background-white;

    background-color: $background-white;
    &:hover {
        color: $bluegray-800;
    }
}
