@import "../../config.scss";
@import "../../../pre-v3/styles/form.scss";

.upgradeIllustration {
    height: 100px;
    margin-top: $spacing-3x;
    margin-right: $spacing-2x;
}

.upgradeSpacing {
    margin-top: $spacing-4x;
    margin-bottom: $spacing-6x;
}

.illustrationContainer {
    vertical-align: top;
    text-align: right;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.description {
    padding: 8px 0;
}
