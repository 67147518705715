.container {
    padding: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
