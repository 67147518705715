.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterButton {
    height: 40px;
    padding: 12px 15px;

    &:hover,
    &:active,
    &:focus {
        background-color: #f6f7fb;
    }
}

.menuButton {
    margin-right: auto;
    margin-left: 10px;
}

.nameLink {
    display: flex;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
