@import "../../../../config.scss";
@import "../../../../styles/form.scss";

.pageBreak {
    margin-top: 24px;
}

.pagePadded {
    margin-bottom: 24px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
}
