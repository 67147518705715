@import "../../../config.scss";

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    > .title {
        @include h4-card-title;
    }
}

.toolbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    column-gap: 24px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.summaryTable {
    :global(.ag-root-wrapper),
    :global(.ag-header),
    :global(.ag-row) {
        background-color: transparent;
    }
    :global(.ag-body-viewport.ag-layout-auto-height) {
        height: 200px;
        overflow-y: scroll;
    }
}
