@import "../../config.scss";

.transferList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .pane {
        display: flex;
        flex-direction: column;
        border: 1px solid $border-dark;
        border-radius: $border-radius;
        word-break: break-all;
        min-width: 50%;

        .header {
            padding: $spacing-2x;

            .paneInputs {
                margin-top: $spacing-1x;
                display: flex;

                input {
                    width: 100%;
                }

                @media (max-width: 1080px) {
                    display: block;
                }
            }

            .actionBtn {
                padding: $spacing-1x $spacing-1x;
                margin-left: $spacing-1x;
                min-width: 90px;
                color: $text-semidark;
                @media (max-width: 1080px) {
                    margin-left: 0;
                    margin-top: 10px;
                    width: 100%;
                }
            }

            .title {
                color: $text-semidark;
            }
        }

        .list {
            overflow-y: auto;
            height: 170px;
            padding: 0 $spacing-1x;

            .listItem {
                padding: $spacing-1x $spacing-2x;
                cursor: pointer;

                &:hover {
                    background-color: $highlight-primary;
                    color: $text-lightest;
                }
            }
        }
    }
}

.rightPane {
    margin-left: $spacing-1x;
}

.divider {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: $border-light;
}

.hiddenInput {
    width: 1px;
    height: 1px;
    position: relative;
    left: 50%;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0;
}

.disabled {
    background-color: $bg-light;
    cursor: default;

    &:hover {
        background-color: $bg-light !important;
        color: $text-dark !important;
    }
}

.faContainer {
    width: 12px;
    text-align: center;
    position: relative;
    top: 2px;
}
