@import "../../../../../styles/form.scss";
@import "../../../../../config.scss";

.pageBreak {
    margin: 50px 0 30px;
}

.marginTop {
    margin-top: $spacing-2x;
}

.marginLeft {
    margin-left: $spacing-2x;
}

.screenContainer {
    display: flex;
    flex-direction: row;
}

.screenContent {
    display: flex;
    flex-direction: column;
    padding: $spacing-2x $spacing-6x;
    width: 70%;
}

.screenText {
    margin-bottom: $spacing-3x;
}

.flexRow {
    display: flex;
    flex-direction: row;
}
