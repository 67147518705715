@import "../../config.scss";

.menuItem {
    list-style: none;
    min-width: 120px;

    a,
    button {
        display: block;
        color: $text-darker;
        padding: $spacing-2x $spacing-2x;
        cursor: pointer;
        background-color: transparent;

        &:hover {
            background-color: $brand-lightest;
        }
    }
}

.menuItemButton {
    border: 0;
    display: block;
    width: 100%;
    text-align: left;
}

.menuItemDivider {
    margin: 0;
}

.menuItemLink {
    &:focus {
        text-decoration: none;
    }
}
