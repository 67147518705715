@import "../../config.scss";

.dialog {
    border: none;
    margin: auto;
    overflow: hidden;

    border-radius: 8px;
    background: #fff;
    box-shadow:
        0px 9px 46px 0px rgba(0, 0, 0, 0.12),
        0px 24px 38px 0px rgba(0, 0, 0, 0.14),
        0px 11px 15px 0px rgba(0, 0, 0, 0.15);

    &[open] {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    &::backdrop {
        background-color: rgba(130, 130, 130, 0.5);
    }
}
.modal {
    padding: 24px;

    min-width: 500px;
    min-height: 156px;
    max-height: 720px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.large {
    width: 800px;
}

.header {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 16px;

    button {
        border: none;
        border-radius: 50%;
        padding: 5px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-items: center;

        svg {
            width: 10px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    h2 {
        font-family: $font-family-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        flex: 1;
    }
}

.content {
    overflow-y: auto;
}

.footer {
    padding-top: 16px;
}
