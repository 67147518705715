.container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.row {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}

.keyInput {
    flex: 2;
}

.valueInputWithAction {
    flex: 3;
}

.valueInputWithoutAction {
    flex: 2;
}
