@import "../../../../pre-v3/config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-4x;
}

.informationContainer {
    display: flex;
    column-gap: $spacing-4x;
}

.itpPolicy {
    display: flex;
    gap: 5px;
}
.itpStatus {
    font-size: 13px;
}

.accessTierConnectionItem {
    text-wrap: pretty;
}
