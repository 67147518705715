@import "./Indented.module.scss";

.rowContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
}

.alignItemsStart {
    align-items: flex-start;
}

.removeButton {
    align-self: center;
}
