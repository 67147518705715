.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    height: 100%;
    padding: 24px;
}

.bottomPadding {
    padding-bottom: 48px;
}
