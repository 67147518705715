@import "../../../pre-v3/config.scss";
@import "../../../pre-v3/styles/overview.scss";

.status {
    padding-left: $spacing-half;
}

.errorContainer {
    padding-bottom: $spacing-3x;
}

.container {
    padding: 24px;
}
