@import "../../../../config.scss";
@import "../../../../styles/page.scss";
@import "../../../../styles/dynamic-form.scss";
@import "../../../../styles/input-list.scss";

.label {
    width: 700px;
    font-weight: normal;
    text-align: left;
    display: block;
    margin-top: $spacing-2x;
    margin-bottom: $spacing-2x;
}

.domainInput,
.portInput {
    margin-right: $spacing-1x;
    margin-bottom: $spacing-1x;
}

.domainInput {
    width: calc(100% - 180px);
}

.portInput {
    width: 80px;
}

.selectContainer {
    margin-top: $spacing-2x;
}

.hosts {
    margin-top: $spacing-4x;
}

.backendLabel {
    margin-top: $spacing-2x;
    margin-bottom: $spacing-2x;
    display: block;
    width: 100%;
}

.checkbox {
    margin-left: $spacing-3x !important;
    margin-right: $spacing-half !important;
}

.checkboxLabel {
    font-weight: normal;
    color: $text-semidark;
}

.disabled {
    display: none;
}

.indent {
    margin-left: 25px;
}

.inputContainer {
    margin-top: $spacing-2x;
    margin-left: 25px;
    width: 90%;
}

.verticalSpacing {
    margin-bottom: $spacing-2x;
}

.toggleContent {
    margin-left: $spacing-4x;
}

.domainListHeader {
    margin-left: $spacing-4x !important;
    margin-top: $spacing-1x;
    margin-bottom: 0 !important;
    display: block;
    width: 100%;
}

.domainList {
    margin-top: 2px;
    max-height: 120px;
    overflow-y: auto;
    color: $text-light;
    font-style: italic;
    margin-left: $spacing-4x;
}

.registeredDomain {
    font-style: italic;
    margin-top: $spacing-1x;
    margin-left: $spacing-1x;
}

.verticalSpace {
    margin-top: $spacing-2x;
}
