@import "../../config.scss";

.header {
    font-size: $font-size-small;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    color: $text-semidark;
    font-weight: 600;
    margin: 0;
    margin-top: $spacing-4x;
    margin-bottom: $spacing-2x;
}
