@import "../../../pre-v3/config.scss";

@mixin iconStyles {
    // To override the width in .svg-inline--fa.fa-w
    width: 20px !important;
    color: $text-light;
    text-align: center;
}

.logo {
    @include iconStyles;

    font-size: 20px;
}

.ios {
    @include iconStyles;

    font-size: 14px;
}

.bullet {
    @include iconStyles;

    font-size: $spacing-3x;
}
