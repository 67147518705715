.container {
    flex: 1;
    padding: 24px 24px 0;

    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.filterContainer {
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    @media (max-width: 800px) {
        flex-direction: column-reverse;
        row-gap: 10px;
    }
}

.filterBar {
    flex: 1;
}

.header {
    display: flex;
    justify-content: space-between;
}

.actionButtons {
    display: flex;
    gap: 8px;
}
