@import "../../../styles.scss";

.input {
    @include border-dark;
    @include regular-text;

    padding: 8px 12px;
    width: 100%;

    &:focus {
        outline: none;
        border-color: $border-dark;
    }

    &::placeholder {
        @include placeholder-text;
    }
}

/* TODO: Simplify after we remove the global styling for input */
input.input:disabled {
    @include disabled-text;
}
