@import "../../../../../../config.scss";

.form {
    margin: 0 !important;
    padding: 0 !important;
}

.formSection {
    margin-left: 0 !important;
}

.certTextArea {
    display: block;
    margin-top: 8px;
    width: 100%;
    height: 200px;
    @include code-text-inactive;
}

.formSubmit {
    width: 20%;
    min-width: 100px;

    * {
        margin-top: 0;
    }
}

.issuingCertsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;
}
