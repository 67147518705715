.rowChild {
    display: flex;
    justify-content: flex-end;
}

.rowWithCopy {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
}

.longRowChild {
    display: none;
}
