.toggleButton {
    display: flex;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.multiInput {
    align-items: start;
}

.hiddenGrid {
    display: none;
}

.exemptionActions {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.actionButton {
    position: relative;
    top: -5px;
}

.modalForm {
    min-width: 800px;
}

.textOverflow p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.addRuleLabel {
    flex: 1;

    p {
        margin-block-end: 0;
    }
}
