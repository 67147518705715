@import "../../../styles.scss";

@mixin borderStyle {
    @include border-dark;
    border-style: dashed;
}

.container {
    @include borderStyle;

    display: flex;
    flex-direction: column;
    background-color: $bg-lightest;
    align-items: center;
    row-gap: 8px;
    padding: 24px;

    .imageIcon {
        width: 24px;
        height: 32px;
        color: $text-lighter;
    }
}

.imageContainer {
    @include borderStyle;

    position: relative;
    background-color: $bg-light;
    padding: 16px;
    height: 106px;
}

.image {
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.removeButton {
    position: absolute;
    top: 8px;
    right: 8px;
}
