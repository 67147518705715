@import "../../../config.scss";

.card {
    flex: 1;
    display: flex;
    flex-direction: column;

    gap: 24px;
    &.empty {
        background-color: $bluegray-200;
    }
}

.cardInfo {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    flex: 1;
    flex-wrap: wrap;
}

.stats {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
}

.usage {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.danger {
    color: $error-button-default;
}
