@import "../../config.scss";

.select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px $spacing-2x;
    border: 1px solid $border-dark;
    border-radius: $border-radius;
    cursor: pointer;
}

.value {
    flex: 1;
}

.placeholder {
    color: $text-light;
}

.dropdown,
.selected {
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    margin: 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: $spacing-2x;
    }
}

.dropdownItem {
    cursor: pointer;

    &:hover {
        background-color: $highlight-primary;
        color: white;
    }
}

.divisor {
    background-color: $bluegray-200 !important;
    color: $text-dark !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.selectedItem {
    cursor: pointer;

    &:hover {
        background-color: $highlight-primary;
        color: white;
    }
}

.hiddenInput {
    width: 1px;
    height: 1px;
    position: relative;
    top: -1px;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.disabled {
    background-color: $bg-light;
    cursor: default;

    &:hover {
        background-color: $bg-light;
        color: $text-dark;
    }
}

.compact {
    padding: 11px;
}

.chevron {
    margin-left: $spacing-1x;
}
