@import "../../../config.scss";

.accessGroup {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border: 1px solid $bluegray-800;
    border-radius: 12px;
}

.labelClassName {
    font-size: large;
}

.labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addButton {
    height: 35px;
}
