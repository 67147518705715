@import "../../config.scss";
@import "../../pre-v3/styles/form.scss";

.gridWithToolbar {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.toolBar {
    margin-top: 0 !important;
}

.nrptConfig {
    margin: 12px 0 36px 0;
}
