@import "../../../../pre-v3/config.scss";

.label {
    color: $bg-darkest;
    display: inline-flex;
    margin-top: $spacing-1x;
    line-height: $line-height;
    width: 10px;
    flex-grow: 1;
}

.labelVertical {
    width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
}

.vertical {
    flex-direction: column;
}

.textBox {
    width: 550px;
}

.title {
    font-weight: 500;
    font-size: $font-size-large;
    line-height: 1.4em;
    color: $text-darkest;
    margin-bottom: $spacing-1x;
}

.subTitle {
    font-weight: 500;
    color: $text-darkest;
}

.subText {
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.list {
    margin: 0;

    li {
        align-items: center;
        list-style: none;
        padding: $spacing-1x;
    }
}

.preReqCheckList {
    margin-bottom: $spacing-4x;
}

.table {
    border-collapse: collapse;
    margin-left: $spacing-5x;
    border: 1px solid $border-light;
    margin-bottom: $spacing-2x;
    margin-top: $spacing-2x;
}

.tr {
    border-bottom: 1px solid $border-light;
}

.text {
    text-align: center;
    color: $text-semidark;
    font-size: $font-size;
    font-weight: normal;
    text-transform: uppercase;
}

.nameColumn {
    text-align: center;
    padding: 12px 40px 12px 60px;
}

.columnColor {
    color: $orange-600;
}

.form {
    margin: $spacing-1x 0;
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-3x;
    padding-top: 24px;
}

.bulletList {
    margin-left: $spacing-6x;
    margin-top: $spacing-half;

    li {
        list-style: square;
    }
}

.checkList {
    display: flex;
    column-gap: $spacing-1x;
}

.info {
    margin-top: $spacing-4x;
}

.buttonLoading {
    background-color: $brand-lighter !important;
}

.subContent {
    margin-left: $spacing-4x;
}

.banner {
    p {
        margin-bottom: 0;
    }
}

.multiInput {
    align-items: start;
}

.preReqLabel {
    p {
        margin-bottom: 0;
    }
}

.flex {
    display: flex;
    justify-content: flex-end;
}

.flexStart {
    align-items: flex-start;
}

.instalLoader {
    width: 100%;
    margin-top: 20px;
}
.fromSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
