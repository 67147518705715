@import "../../../../styles/form.scss";

.formLabel {
    width: 200px;
}

.formColumn {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
