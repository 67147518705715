@import "../../../styles.scss";

$icon-component-width: 32px;

div.select {
    .input {
        @include regular-text;
        @include border-dark;

        padding: 8px $icon-component-width 8px 12px;
        cursor: pointer;
        line-height: 1.15;
        min-height: unset;

        &.disabled {
            @include disabled-text;
        }
    }

    .iconWrapper {
        position: absolute;
        right: 0;
        height: 100%;
        width: $icon-component-width;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            color: $text-dark;
        }
    }

    fieldset {
        border: none;
    }
}

.divisor {
    background-color: $text-lighter !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
}

.placeholder {
    @include placeholder-text;
}

li.menuItem {
    @include regular-text;
    font-weight: normal;
    font-family: inherit;

    &.itemSelected {
        color: $text-lightest;
        background-color: $brand;

        &:hover {
            background-color: $brand;
        }

        &:focus-visible {
            background-color: $brand;
        }
    }

    &:hover {
        background-color: $bg-light;
    }
}
