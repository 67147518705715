.accordionChildren {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 16px 0;
}

.formRow {
    align-items: flex-start;
}

.imageUploadContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.imageUploadSpecification {
    align-self: center;
}

.previewContainer {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.preview {
    align-self: center;
    width: 800px;
    height: 500px;
}

.textarea {
    resize: vertical;
}

.section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px 24px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    padding: 0 0 24px 0 !important;
}
