@import "../../../styles.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: $bg-light;
    padding: 8px 24px;
    border-radius: $border-radius;
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: $brand;

    &:hover {
        color: $brand-dark;
    }

    &.clickable {
        cursor: pointer;
    }
}

.fakeInput {
    opacity: 0;
    width: 100%;
    height: 100%;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    top: 0;
    z-index: -1;
}

.menuSelectWrapper {
    position: relative;
    width: fit-content;
}
