@import "../../config.scss";

.navButton {
    display: block;
    color: $text-lighter;
    text-transform: uppercase;
    height: 100%;
    font-weight: 500;
    letter-spacing: $letter-spacing;
    border-bottom: 5px solid transparent;

    &:global(.navButtonActive) {
        border-bottom: 5px solid $brand;
    }

    &:hover {
        color: $text-lightest;
        border-bottom-color: $brand;
    }

    &:focus {
        color: $text-lightest;
        text-decoration: none;
        outline: none;
    }
}
