@import "../../styles.scss";

.actionBar {
    padding: 0 24px;
}

.outerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 49px;
}

.title {
    display: block;
    @include page-title-text;

    margin-top: 0;
}

.breadcrumb {
    display: inline-block;
    margin-top: 24px;
    white-space: nowrap;
    @include regular-text;

    color: $text-light;

    a {
        color: $text-light;

        &:hover {
            color: $text-dark;
        }
    }
}

.breadcrumbSeparator {
    margin: 0 8px 0 4px;
    color: $text-light;
    display: inline-block;
    transform: rotate(90deg);
}

.actionsContainer {
    min-height: 49px;
    padding-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
}

.actionBarItem {
    padding-left: 16px;
    padding-right: 16px;
}
