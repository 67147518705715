.form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 0 24px;
    overflow: auto;
    margin-bottom: 48px;

    section {
        min-width: 410px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    > section {
        max-width: 750px;
        width: 60%;
    }

    input:not([type="checkbox"]) {
        width: 100%;
    }
}

.archivalDateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}
