@import "../../../../styles/form.scss";
@import "../../../../config.scss";

.mainContainer {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;
    hr {
        margin: 8px 0;
    }
}
.formLabel {
    width: 200px;
}

.formSubmit {
    width: 100%;
}

.error {
    margin: 24px;
}

.formColumn {
    display: flex;
    flex-direction: column;
    gap: 8px;
    p {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        color: $text-light;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    margin-left: auto;
    gap: 16px;
}

.updateButton {
    margin-left: auto;
}

.passwordInput {
    border-radius: 4px;
    border: 1px solid $bluegray-300;
    height: 33px;
    &:focus-within {
        outline: 1px solid $bluegray-300 !important;
    }
    input {
        background-color: white;
    }
    button {
        background-color: white !important;
    }
}

.userHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
}
