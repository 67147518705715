@import "../../config.scss";

.container {
    position: absolute;
    left: -55px;
    transform: rotate(-45deg);
    width: 200px;
    background: $brand;
    bottom: 31px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 0;
}
