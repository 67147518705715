@import "../../../styles.scss";

.accordionContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

div.accordion {
    margin: 0;
    height: fit-content;

    @include border-dark;

    &::before {
        display: none;
    }

    &,
    &.accordionExpanded {
        margin: 0;
    }

    .accordionDetails {
        padding: 0 32px 16px;
        margin: 0;
    }

    .pageBreak {
        margin: 0 0 24px;
    }
}

div.accordionSummary {
    &,
    &.accordionSummaryExpanded,
    &:global(.Mui-expanded) {
        gap: 8px;
        padding: 16px 32px;
        min-height: unset;

        .accordionSummaryContent {
            margin: 0;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
        }
    }

    &.accordionSummaryDisabled {
        cursor: auto !important;
    }

    .accordionSummarySwitchWrapper {
        margin-left: auto;
        pointer-events: none;
    }

    .expandIcon {
        transition: transform 150ms linear;

        &.rotate {
            transform: rotate(180deg);
        }
    }

    &.accordionSummaryWithExpand {
        flex-direction: row-reverse;
    }
}

div.accordionSummary {
    cursor: default !important;
}

.children {
    display: flex;
    gap: 8px;
    margin-left: auto;
}

.labelContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}
.label {
    display: flex;
    align-items: center;
}

.description {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #a2a5b7;
    max-width: 800px;
    margin-top: 8px;
}
