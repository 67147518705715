@import "../../config.scss";

$transition-time: 0.4s;

.container {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, -90vh);
    width: 90%;
    height: 90vh;
    z-index: $msw-csc-z-index;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transition: transform $transition-time ease-in-out;
}

.resizing {
    transition: unset;
}

.open {
    transform: translate(-50%, 0);

    .toggleButton {
        transform: rotate(180deg);
        bottom: 0;
    }
}

.iframe {
    position: absolute;
    top: 0;
    transition: opacity 0s $transition-time;

    border: none;
    transform-origin: top center;
    filter: drop-shadow(1px 4px 7px $card-box-shadow);
    background-color: $background-white;
}

.toggleButton {
    position: absolute;
    bottom: -16px;
    width: 32px;
    height: 16px;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
    background-color: $background-darkest;
    z-index: $msw-csc-z-index;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 6px solid $background-white;
    }
}

.loading {
    background-color: $background-white;
    z-index: $msw-csc-z-index;
    height: 100%;
}
