.tabContent {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &.centered {
        align-items: center;
    }
}

.hideButton {
    visibility: hidden;
}

.requestHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }
}

.buttons {
    display: flex;
    column-gap: 8px;
}

.configureImage {
    width: fit-content;
    height: fit-content;
}
