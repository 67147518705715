@import "../../../pre-v3/config.scss";

.page {
    display: flex;
    flex-direction: column;
    height: 100vh;

    // Don't change until we redesign OIDC views
    input[type="text"],
    input[type="password"],
    input[type="email"] {
        padding: $spacing-2x !important;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 15px 45px;
    width: 400px;
    margin-top: 5%;
    margin-bottom: $spacing-4x;
}

.logo {
    width: 80%;
    align-self: center;
    margin: 50px 0;
}

.form {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

// Don't change until we redesign OIDC views
.resetPassword {
    text-align: center;
    font-size: $font-size-large;
    margin-bottom: $spacing-4x;
    font-weight: bold;
}

.space-below {
    margin-bottom: 20px;
}

// Don't change until we redesign OIDC views
.submitButton {
    text-transform: uppercase;
    padding: 15px !important;
}

.alignCenter {
    align-self: center;
}

.textAlignCenter {
    text-align: center;
    padding: 8px;
}

// Don't change until we redesign OIDC views
.resetPasswordExplainer {
    margin-bottom: $spacing-4x;
}

.verticalMargin {
    margin: $spacing-2x 0;
}
