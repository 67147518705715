@import "../../../../../../../../../pre-v3/config.scss";

.form {
    padding-top: $spacing-2x;
}

.actionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.banner {
    margin-bottom: $spacing-2x;
}

.button {
    align-self: flex-end;
}
