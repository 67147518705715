@import "../../../../config.scss";
@import "../../../../styles/page.scss";
@import "../../../../styles/form.scss";

.form {
    width: 60%;
    max-width: 2000px;
}

.formLabel {
    width: 200px;
}

.cidrLabel {
    vertical-align: top;
    padding-top: 20px !important;
}

.container {
    display: flex;
    flex-direction: row;
}

.pageBreakSwitch {
    margin-left: auto;
}

.formRow {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
