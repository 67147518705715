@import "../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $border-light;
    border-radius: $border-radius;
    width: 100%;

    .input {
        flex: 1;
        border: 0;
        border-radius: $border-radius;
        min-width: 100px;
        padding: 11px $spacing-2x;

        &::placeholder {
            color: $text-placeholder;
        }

        &:focus {
            outline: none;
            background-color: $bg-lighter;
        }

        height: 100%;
    }
}

.action {
    padding: 10px 15px;
    border: 0;
    border-radius: $border-radius;
    color: $text-dark;
    border-left: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: $bg-lighter;
    }

    &:active {
        background-color: $bg-light;
    }

    &:focus {
        outline: 0;
    }
}
