@import "../../../styles.scss";

.topNav {
    background-color: $bg-darkest;
    height: 50px;
    padding: 0 24px;
    border-top: 2px solid $brand;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
}

.sonicWallCSELogo {
    background-image: url("../../../images/cse-horiz-white.svg");
    background-size: 158px auto;
    background-repeat: no-repeat;
    background-position: 0 50%;
    display: inline-block;
    width: 160px;
    height: 100%;
    cursor: pointer;
}

.topNavChildren {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    flex: 1;
    @include small-text;
}

a.topNavSettings {
    align-self: flex-end;
    font-size: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 6px;
}

.userMenu {
    align-self: flex-end;
}

span.supportMenu {
    align-self: flex-end;
    font-size: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 6px;
}

.version {
    list-style: none;
    padding: 0;
    border-top: 1px solid $border-dark;
    font-size: 8px;
    height: 10px;
    text-align: right;
}
