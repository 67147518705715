.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    width: min-content;

    p {
        text-align: center;
    }
}

.topText {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bottomText {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
