.form {
    display: flex;
    flex-direction: column;
    row-gap: 42px;
    padding: 0;
}

.noPadding {
    padding: 0;
}
