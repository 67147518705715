@import "../../config.scss";

.container {
    display: flex;
    flex-direction: column;
}

.lookupInput {
    border: 1px solid $border-light;
    border-radius: $border-radius;
    min-width: 300px;

    &::placeholder {
        color: $text-placeholder;
    }

    &:focus {
        outline: none;
        background-color: $bg-lighter;
    }
}

.lookupResultsContainer {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 250px;
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    margin: 0;
}

.selectedItem,
.lookupResult {
    list-style: none;
    display: block;
    color: $text-dark;
    line-height: $line-height * 1.6;
    font-size: $font-size;
    cursor: pointer;
    padding: 0 $spacing-2x;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: $spacing-1x;
    text-decoration: none;
    width: 100%;
    text-align: left;

    &:hover {
        background-color: $highlight-primary;
        color: white;
    }

    &:focus {
        background-color: $highlight-primary;
        color: white;
        text-decoration: none;
    }
}

.lookupResult {
    padding-left: 38px;
}

.selectedContainer {
    margin-block-start: 0;
    margin-inline-start: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    margin: 0;
    border-bottom: 1px solid $border-dark;
    list-style: none;
}

.selectedItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.selectedIcon {
    margin-right: $spacing-1x;
}
