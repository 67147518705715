.loadmask {
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(255 255 255 / 50%);
}

.spinner {
    text-align: center;
    position: absolute;
    top: 35%;
    width: 100%;
}
