.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.isServiceTunnelSecured {
    display: flex;
    justify-content: flex-end;
}
