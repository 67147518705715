@import "../../config.scss";

@mixin titles {
    font-family: $font-family-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 500px;
}

.topContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
        @include titles;
    }
}

.licensesContainer {
    h2 {
        @include titles;
    }

    .titleDescription {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.cards {
    display: flex;
    gap: 24px;
    align-items: stretch;
}

.description {
    padding-bottom: 24px;

    display: flex;
    flex-direction: column;
    gap: 24px;
}

.errorBanner {
    a {
        color: $error-button-default;
        text-decoration-line: underline;

        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
