@import "../../../config.scss";

.cancelBtn {
    margin-right: $spacing-2x;
}

.buttons {
    display: flex;
    gap: 8px;
}
