@import "../../config.scss";

.label {
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    width: fit-content;
    border-radius: 4px;
}

.generic-primary {
    background-color: $feedback-generic-1-light;
    color: $feedback-generic-1-dark;

    &.dark {
        background-color: $feedback-generic-1-dark;
        color: $text-white;
    }
}
.generic-secondary {
    background-color: $feedback-generic-2-light;
    color: $feedback-generic-2-dark;

    &.dark {
        background-color: $feedback-generic-2-dark;
        color: $text-white;
    }
}
.information {
    background-color: $feedback-info-light;
    color: $feedback-info-dark;

    &.dark {
        background-color: $feedback-info-dark;
        color: $text-white;
    }
}
.error {
    background-color: $feedback-error-light;
    color: $feedback-error-dark;

    &.dark {
        background-color: $feedback-error-dark;
        color: $text-white;
    }
}
.warning {
    background-color: $feedback-warning-light;
    color: $feedback-warning-dark;

    &.dark {
        background-color: $feedback-warning-dark;
        color: $text-white;
    }
}
.success {
    background-color: $feedback-success-light;
    color: $feedback-success-dark;

    &.dark {
        background-color: $feedback-success-dark;
        color: $text-white;
    }
}
