@import "../../styles.scss";
@import "../../config.scss";

@mixin cardStyle {
    border-radius: 12px;
    border: 1px solid $bluegray-600;
    background: $background-white;
    filter: drop-shadow(0px 3px 4px $card-box-shadow);
    min-height: 130px;
    padding: 20px 24px;
}

.container {
    display: flex;
    flex-direction: row;
    gap: 32px;
}

.statusInfo {
    @include cardStyle;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    min-width: 350px;
    height: auto;
    width: 33.33%;
    overflow-x: hidden;

    &.success {
        border-color: $success;
        background-color: $success-light;
    }

    &.warning {
        border-color: $warning;
        background-color: $warning-light;
    }

    &.error {
        border-color: $error;
        background-color: $error-lightest;
    }

    &.disabled {
        border-color: $text-dark;
        background-color: $text-lighter;
    }
}

.statusLabel {
    @include small-uppercase-text;
}

.columnFlexContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.mainContainer {
    @include cardStyle;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 66.6%;
    overflow: hidden;
    min-height: 130px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.statusRow {
    display: flex;
    column-gap: 4px;
}

.iconPadding {
    padding-left: 28px;
}

.status {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
}

.header {
    font-size: 16px;
    font-weight: 600;
    display: block;
}

.label {
    @include small-uppercase-text;

    width: 125px;
}

.value {
    @include regular-text;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
}

.keyValueContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
}
