@import "../../../config.scss";
@import "../../../styles/page.scss";
@import "../../../styles/dynamic-form.scss";

.statusButton {
    margin-right: $spacing-1x;
}

.cancelButton {
    margin-right: $spacing-1x;
}

.formLabel {
    width: 180px;
    text-align: right;
}

.submitSection {
    margin-left: 48px;
}

.buttons {
    display: flex;
    gap: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    padding-bottom: 0;
}
