@import "../../../../pre-v3/config.scss";

.cell {
    display: flex;
    column-gap: 10px;
    text-transform: uppercase;
}

.pendingColor {
    color: $bluegray-300;
}

.bannedColor,
.expiredColor,
.alwaysDenyColor {
    color: $red-900;
}

.lowTrustLevelColor {
    color: $red-500;
}

.mediumTrustLevelColor {
    color: $yellow-500;
}

.highTrustLevelColor {
    color: $green-500;
}
