@import "../../../../config.scss";

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px 32px;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid $bluegray-600;

    > .header {
        padding: 8px 0;
        border-bottom: 1px solid $bluegray-600;
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 51px;

        > .title {
            @include h4-card-title;
        }
    }
}

.pacFileButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

.managedDomainsCard {
    height: 565px;
}

.settingsTab {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.switcher {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;

    .label {
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
    }
}

.downloadPAC {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }

    .label {
        font-size: 14px;
        font-weight: 500;
        color: $text-darkest;
        text-transform: uppercase;
    }
}

.managedDomains {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
}

.modalContainer {
    width: 500px;
}

.domainInput {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.infoButton {
    border: none;
    background: none;
    padding: 0;
}

.pickerForm {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}

.filePickerButton {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    border: 1px solid $bluegray-600;
    border-radius: 30px;
    background-color: $bluegray-100;
    color: $text-darkest;
    min-width: 40px;
}

.filePicker {
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 30px;
}

.filename {
    padding-left: 8px;
}

:global(.ag-theme-material) {
    font-family: $font-family-sans-serif;
}

.loader {
    font-size: 1.5em;
}
