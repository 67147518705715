@import "../../../../../pre-v3/config.scss";
@import "../../../../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-3x;
}

.methodSelect {
    gap: 100px !important;
    align-items: center !important;
}

.title {
    font-family: $font-family-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.step {
    display: flex;
    flex-direction: column;
    gap: 8px;
    label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
