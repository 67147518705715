@import "../../config.scss";
@import "../../../styles.scss";

.button {
    display: inline-block;
    background-color: $brand;
    color: $text-lightest;
    padding: 10px 15px;
    width: 100%;
    border-radius: 2px;
    border: 0;
    cursor: pointer;
    border-color: transparent;

    &:hover {
        background-color: $brand-dark;
    }

    &:focus {
        outline: 0;
        background-color: $brand-dark;
    }

    &[disabled] {
        background-color: $bg-light;
        color: $text-dark;
        border-color: $border-dark;
    }
}

.loadingIcon {
    margin-right: 5px;
}
