@import "../../../../../../../pre-v3/config.scss";

.form {
    margin: $spacing-6x 0;
}

.multiInput {
    align-items: start;
}

.accordionContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-bottom: 16px;
}
