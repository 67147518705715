@import "../../../../../../../../styles.scss";

.container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}

.platform {
    width: 100px;
    display: flex;
    gap: 6px;
}

.outerContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.select {
    width: 350px;
}

.icon {
    color: $text-light;
    font-size: $icon-size-normal;
}

.label {
    @include small-text;
}

.selectedPlatformsDesc {
    @include regular-text;
}

.selectOS {
    display: flex;
    flex-direction: row-reverse;
}
