@import "../../../../config.scss";
@import "../../../../styles/page.scss";
@import "../../../../styles/dynamic-form.scss";

.label {
    width: 700px;
    font-weight: normal;
    text-align: left;
    display: block;
}

.verticalSpacing {
    margin-top: $spacing-4x;
}

.selectContainer {
    margin-top: $spacing-2x;
    margin-left: 25px;
    width: 90%;
}

.port {
    display: block;
    margin-top: $spacing-2x !important;
    margin-left: 25px !important;
    width: 100px;
}
