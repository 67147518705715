.container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.grid {
    &:global(.ag-theme-material) {
        :global(.ag-center-cols-clipper) {
            min-height: unset;
        }
    }
}

.hide {
    display: none;
}

.flex {
    display: flex;
}

.fakeInput {
    opacity: 0;
    width: 1px;
    height: 1px;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}
