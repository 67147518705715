@import "../../../../../../../../pre-v3/config.scss";

.container {
    padding: $spacing-4x;
}

.pillContainer {
    margin-bottom: $spacing-4x;
}

.flex {
    display: flex;
}
