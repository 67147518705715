@font-face {
    font-family: "Red Hat Display";
    font-weight: 400;
    src: url("RedHatDisplay-Regular.ttf");
}

@font-face {
    font-family: "Red Hat Display";
    font-weight: 500;
    src: url("RedHatDisplay-Medium.ttf");
}

@font-face {
    font-family: "Red Hat Display";
    font-weight: 600;
    src: url("RedHatDisplay-SemiBold.ttf");
}

@font-face {
    font-family: "Red Hat Display";
    font-weight: 700;
    src: url("RedHatDisplay-Bold.ttf");
}
