.SelectInput {
    display: flex;
    position: relative;
    width: 100%;

    .selection {
        width: 40%;

        &:hover,
        &:focus {
            z-index: 1;
        }

        .selectionSelect {
            margin-right: -1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

input.value {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;

    &:hover,
    &:focus {
        z-index: 1;
    }
}
