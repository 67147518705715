@import "../../../config.scss";

.number {
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: $font-family-serif;
}
.text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: max-content;
}

.link {
    cursor: pointer;
    &:hover {
        .text {
            text-decoration: underline;
        }
    }
}
