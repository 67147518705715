@import "../../config.scss";

.container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    width: 76px;
    padding: 0px 12px 14px 12px;
    background: $background-white;
    border-right: 1px solid $bluegray-500;
    transition: all 0.2s ease-in-out;

    &:hover .expandButton {
        visibility: visible;
    }
}

.logo {
    display: flex;
    justify-content: center;
    padding: 44px 0px 0;

    img {
        height: 29px;
        max-width: 38px;
        transform-origin: left;
        transition:
            height 0.2s ease-in-out,
            max-width 0.1s ease-in-out;
    }
}

$expandContainerHeight: 57px;
$expandButtonHeight: 24px;
$expandButtonMargin: calc(($expandContainerHeight - $expandButtonHeight) / 2);

.expandButton {
    align-self: center;
    display: flex;
    align-items: center;
    column-gap: 6px;
    visibility: hidden;

    height: $expandButtonHeight;
    padding: 0 8px;
    margin: $expandButtonMargin 0;
    background: $background-white;
    border-radius: 4px;
    outline: 4px solid $gray-100;
    border: 1px solid $bluegray-500;
    cursor: pointer;

    &:hover {
        align-self: flex-start;
        @include shadow;

        .expandButtonLabel {
            display: block;
        }
    }
}

.expandButtonLabel {
    display: none;
    white-space: nowrap;
}

.navigationLinks {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2px;
    flex-shrink: 0;

    li {
        list-style: none;
        border-radius: 8px;
    }
}

.navigationLink {
    color: $text-darkest;
    font-weight: 600;
    font-size: 14px;

    display: flex;
    align-items: center;

    padding: 14px;
    border-radius: 8px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0);
    max-width: 48px;
    transition:
        background 0.3s ease-in-out,
        max-width 0.2s ease-in-out,
        box-shadow 0.3s ease-in-out,
        color 0.1s ease-in-out;

    svg[role="img"] {
        min-width: 20px;
        height: 16px;
    }

    &:hover {
        @include shadow;
        color: $text-white;
        background: $bluegray-800;
        max-width: 161px;

        .navigationLinkLabel {
            visibility: visible;
            opacity: 1;
            max-width: 100%;
            transform: scaleX(1);
            margin-left: 14px;
        }
    }

    &[aria-current="page"] {
        color: $text-white;
        background: $background-darkest;
    }
}

.navigationLinkLabel {
    visibility: hidden;
    white-space: nowrap;
    opacity: 0;
    transform-origin: left;
    transform: scaleX(0);
    max-width: 0px;
    transition:
        max-width 0.1s ease-in-out,
        opacity 0s ease-in-out,
        visibility 0.4s ease-in-out,
        transform 0s ease-in-out;
}

.signOut {
    min-height: 15px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;

    padding: 8px;
    background: $background-white;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    &:hover {
        background-color: $bluegray-200;
    }
}

.signOutLabel {
    display: none;
    width: 0px;
    opacity: 0;
    transition:
        width 0.1s linear,
        opacity 0.5s linear;
}

.expanded {
    &.container {
        width: 186px;
    }

    .logo {
        padding: 44px 8px 0;

        img {
            max-width: 122px;
        }
    }

    .expandButton:hover {
        align-self: center;
    }

    .expandButtonLabel {
        display: block;
    }

    .navigationLinks {
        align-items: stretch;
    }

    .navigationLink {
        min-width: 161px;
    }

    .navigationLinkLabel {
        visibility: visible;
        max-width: 100%;
        opacity: 1;
        transform: scaleX(1);
        margin-left: 14px;
    }

    .signOut {
        align-self: stretch;
        padding: 8px 30px 8px 24px;
        border-radius: 30px;
        border-color: $bluegray-900;
    }

    .signOutLabel {
        display: block;
        opacity: 1;
        width: 100%;
        white-space: nowrap;
    }
    .goBack {
        visibility: visible;
    }
}

.goBack {
    visibility: hidden;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}
