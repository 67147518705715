@import "../../../config.scss";

.content {
    padding: $spacing-2x 0;
}

.list {
    margin: $spacing-2x auto;
    padding: $spacing-2x $spacing-3x;
    border: 1px solid black;
}
