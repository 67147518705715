@import "../../config.scss";

.toolbar {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.toolbarButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;

    div {
        display: flex;
    }
}

.toolbarSearchContainer {
    width: 250px;
    display: inline-block;
    margin-right: $spacing-1x;
}

.faContainer {
    width: 12px;
    text-align: center;
    position: relative;
    top: 2px;
}
