@import "../../../styles.scss";
@import "../../../config.scss";

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
}

.statusInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    min-width: 350px;
    height: auto;
    width: 33.33%;
}

.statusLabel {
    @include small-uppercase-text;
}

.columnFlexContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
}

.flexGap6x {
    gap: 6px;
}

.flexGap8x {
    gap: 8px;
}
